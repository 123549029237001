import { lazy } from 'react';
import Roles from '../../common/Roles';

const FlyerCreate = lazy(() => import('../../pages/flyer/FlyerCreate'));
const FlyerList = lazy(() => import('../../pages/flyer/FlyerList'));
// const EventDetails = lazy(() => import('../pages/events/EventDetails'));
const FlyerUpdate = lazy(() => import('../../pages/flyer/FlyerUpdate'));


export const FlyerRoutes = [
  {
    path: '/flyer/create',
    exact: true,
    component: FlyerCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/flyer/list',
    exact: true,
    component: FlyerList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/flyer/:id/edit',
    exact: true,
    component: FlyerUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
