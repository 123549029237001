import { lazy } from 'react';
import Roles from '../../common/Roles';

const AccountDeleteRequests = lazy(() => import('../../pages/manage_users/AccountDeleteRequests'));

export const ManageUsers = [
  {
    path: '/manage_users/account_delete_requests',
    exact: true,
    component: AccountDeleteRequests,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
