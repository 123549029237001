import { lazy } from 'react';
import Roles from '../../common/Roles';

// const QuickChat = lazy(() => import('../../pages/quick_chat/QuickChat'));
const ManualOrderDetails = lazy(() => import('../../pages/quick_chat/ManualOrderDetails'));


export const QuickChatRoutes = [
  {
    path: '/quick-chat',
    exact: true,
    component: ManualOrderDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
