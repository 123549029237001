export const IT = {
  //WEB__APPLICATION__TEXT_(english)

  //COMMON_TEXT
  bigdata: "BIG DATA INNOVATION GROUP",
  welcome: "Benvenuto",
  Next: "Prossimo",
  EDIT: "MODIFICARE",
  CREATE: "CREARE",
  RENAME: "Rinominare",
  Details: "Dettagli",
  Optional: "Opzionale",
  Start_Date: "Data iniziale",
  End_Date: "Data finale",
  Active: "Attivo",
  write_here: "Scrivi qui......",
  SUBMIT: "INVIA",
  Successfully_Done: "Operazione effettuata con successo",
  Something_Went_Wrong_Try_Again: "Qualcosa e' andato storto, riprova... ",
  Your_item_has_been_deleted: "Il tuo articolo e' stato eliminato",
  Search_Option: "Ricerca",
  First_Name: "Nome",
  Last_Name: "Cognome",
  Username: "Nome utente",
  Email: "E-mail",
  Mobile: "Mobile",
  New_Password: "Nuova password",
  Confirm_New_Password: "Conferma la nuova password",
  Select_one: "Selezionane uno",
  Action: "Azione",
  type: "Tipo",
  total: "Totale",
  Back: "Indietro",
  Update: "Aggiornare",
  Birth_Date: "Data di nascita",
  Gender: "Sesso",
  Phone_Number: "Numero di telefono",
  Postal_Code: "CAP",
  Province: "Provincia",
  Search: "Ricerca",
  Municipality: "Comune",
  Address: "Indirizzo",
  Total_Family_Member: "Numero dei componenti del nucleo di famiglia",
  Fidelity_Card_Collection: "Dove ritira la Fidelity Card",
  FROM_SALE_POINT: "DAL PUNTO VENDITA",
  SEND_TO_POSTAL_CODE: "INVIA A CODICE POSTALE",
  SUBMIT_YOUR_FILE: "INVIA IL TUO FILE",
  SELECTED: "SELEZIONATO",
  LAST_FILE_IS_NOT_FULLY_UPLOADED_SOMETHING_WENT_WRONG:
    "L'ULTIMO FILE NON E' COMPLETAMENTE CARICATO QUALCOSA E' ANDATO STORTO",
  Not_exist: "Non esiste",
  Field_is_empty: "Il campo e' vuoto",
  Are_you_sure: "Sei sicuro?",
  You_wont_be_able_to_revert_this:
    "Non sarai in grado di ripristinare se vai avanti!",
  Yes_delete_it: "Si, cancellalo!",
  Yes_confirm_it: "Si, confermalo!",
  Cancel: "Annulla",
  Operation_Successful: "Operazione riuscita",
  Product_Description: "Descrizione del prodotto",
  Name: "Nome",
  REMOVE: "RIMUOVERE",
  Successfully_Done: "Operazione effettuata con successo",
  OOPS: "OOPS",
  Created_Successfully: "Creato con successo",
  print: "Stampa",
  print_preview: "Anteprima di stampa",
  to: "a",
  please_enter_empty_field: "Per favore, riempi i campi necessari!",
  export: "Esportare",
  export_all: "Esporta tutto",
  exporting: "Esportare...",
  Download: "Scarica",
  Download_all: "Scarica tutto",
  Previous_Exported_File_Download:
    "Download del file esportato precedentemente",
  LAST_FILE_EXPORT_FAILED: "ULTIMA ESPORTAZIONE FILE, FALLITA!",
  there_is_no_data: "Non ci sono dati",

  //Header
  help: "Aiuto",

  //LOGIN/FORGOT_PASS_MODULE
  login: "Login",
  forgot_password: "Hai dimenticato la password",
  The_Password_Recovery_Questions: "Domande per il recupero della password",
  Question_No_1: "Domanda n. 1",
  Question_No_2: "Domanda n. 2",
  Question_No_3: "Domanda n. 3",
  Change_Password: "Cambia la password",
  Password: "Password",
  Confirm_Password: "Conferma password",
  password_must_be_at_least_6_characters_in_length:
    "La password deve contenere almeno 6 caratteri",
  Make_sure_all_fields_are_filled_where_password_and_confirm_password_should_be_matched:
    "Assicurati che tutti i campi siano riempiti e che la password e la conferma della password siano uguali",
  password_mismatched: "Password non corrispondente",
  Old_Password: "Vecchia password",
  There_is_no_security_questions_you_will_not_be_able_to_recover_your_password_in_future:
    "Non ci sono domande di sicurezza, non sarai in grado di recuperare la tua password in futuro",
  PASSWORD_MISMATCHED_TRY_AGAIN: "LA PASSWORD NON CORRISPONDE, RIPROVA",
  Please_Enter_You_Old_Password: "Inserisci la tua vecchia password",

  Make_sure_all_fields_are_filled:
    "Assicurati che tutti i campi siano riempiti",
  Your_Answer: "La tua risposta",
  Your_Question: "La tua domanda",
  Your_Security_Questions_For_Future_Password_Reset_Authorizations:
    "Le tue domande di sicurezza per future autorizzazioni alla reimpostazione della password",

  enter_one_time_password: "Si prega di inserire la password monouso",
  to_verify_your_account: "per verificare il tuo account",
  code_has_been_sent:
    "Un codice e' stato inviato al tuo account e-mail registrato",
  your_code_is_wrong: "Inserisci il codice corretto",

  //SIDE_BAR
  home: "Pagina principale",
  fidelity_card_request: "Richiesta carta fedelta'",
  fidelity_card: "Carte fedelta'",
  fidelity_card_upload: "Caricamento carte fedelta'",
  fidelity_card_list: "Elenco carte fedelta'",
  product: "Prodotti",
  product_excel_upload: "Caricamento in Excel dei prodotti",
  product_list: "Elenco prodotti",
  product_image_upload: "Caricamento immagini dei prodotti",
  product_image_list: "Elenco immagini dei prodotti",
  product_info: "Prodotti in evidenza",
  product_info_create: "Crea nuovo prodotto in evidenza",
  product_info_list: "Elenco dei prodotti in evidenza",
  product_orders: "Ordini dall'eCommerce",
  last_minute_offer: "Offerte Last Minute",
  offer_create: "Crea nuova offerta",
  offer_list: "Elenco offerte",
  user_points: "Punti utente",
  points_list: "Elenco punti",
  points_conversion_list: "Elenco conversione punti",
  points_conversion_requests: "Richieste di Conversione Punti",
  export_user_points: "Esporta punti utente",
  communication: "Comunicazioni",
  send_message: "Invia messaggio",
  message_list: "Elenco dei messaggi",
  institutional_flyers: "Volantini digitali",
  create_flyer: "Crea volantino",
  flyers_list: "Elenco volantini",
  sales_points: "Punti vendita",
  upload_sales_point_info: "Carica le informazioni sui punti vendita",
  sales_point_list: "Elenco punti vendita",
  delivery_slot: "Slot di consegna",
  sales_points_delivery_slot: "Slot di consegna dei punti vendita",
  upload_delivery_slots: "Carica slot di consegna",
  special_delivery_dates: "Date di consegna speciali",
  ticket_management: "Gestione dei ticket",
  news: "Notizie",
  create_news: "Crea notizie",
  news_list: "Elenco delle notizie",
  events: "Eventi",
  create_event: "Crea Evento",
  event_list: "Elenco eventi",
  reviews: "Recensioni",
  create_reviews: "Crea recensioni",
  reviews_list: "Elenco delle recensioni",
  advance_profiling: "Profilazione avanzata",
  create_survey: "Crea sondaggio",
  survey_list: "Lista dei sondaggi",
  notice: "Avviso allo Start",
  contact: "Contatto",
  privacy_agreement: "Accordo sulla privacy",
  privacy_terms_of_services: "Privacy & termini di servizio",
  cancellation_requests: "Richieste di cancellazione",
  manage_user_account: "Gestisci account utenti",
  account_delete_request: "Richieste di cancellazione dell'account",
  settings: "Impostazioni",
  max_items: "Numero massimo articoli",
  branch_users: "Utenti dei punti vendita",
  payment_key: "Chiave di pagamento",
  admin_mail: "Email di notifica dell'amministratore",
  my_account: "Il mio account",
  change_password: "Cambiare la password",
  security_questions: "Domande di sicurezza",
  image_management: "Gestione immagini",

  //FIDELITY_CARD_REQUEST_MODULE
  Fidelity_Card_Requests_List: "Elenco richieste carte fedelta'",
  Temporary_Username: "Nome utente temporaneo",
  Full_Name: "Nome e cognome",
  Fidelity_Card_Request_Details: "Dettagli richiesta carta fedelta'",
  SALE_POINT_DETAILS: "DETTAGLI DEL PUNTO VENDITA",
  Sale_Point_Name: "Nome punto vendita",
  Sale_Point_Code: "Codice punto vendita",
  Sale_Point_Address: "Indirizzo del punto vendita",
  There_is_no_requests: "Non ci sono richieste",
  new_request: "Richieste non viste",

  //FIDELITY_CARD_MODULE
  FIDELITY_CARD_INFO_UPLOAD: "CARICA INFO CARTE FEDELTA'",
  FIDELITY_CARD: "CARTE FEDELTA'",
  There_is_no_fidelity_card_info:
    "Non ci sono informazioni sulla carta fedelta'",
  IDCard: "IDFidelityCard",
  TipoProfilaz: "TipoProfilaz",
  Comune_di_Residenza: "Comune di Residenza",
  Fidelity_Card_Details: "Dettagli della carta fedelta'",
  EXCEL_UPLOAD_INFO: "INFORMAZIONI SULL'UPLOAD DA EXCEL",
  USER_GIVEN_INFO: "INFORMAZIONI FORNITE DALL'UTENTE",
  File_Is_Uploading: "Il file e' in caricamento",
  IDPuntoVendita: "IDPuntoVendita",
  Regione_di_Residenza: "Regione di Residenza",
  Stato_Fam: "Stato Famiglia",
  Num_Figli: "Numero Figli",
  Altri_Comp: "Altri Componenti",
  Spesa_Altri_A: "Spesa per Altri (A)",
  Se_SI_quanta_spesa: "Se SI quanta spesa +/- ? (B)",
  Verification_Status: "Stato di verifica",
  Verification: "Verifica",
  Verified: "Verificato",
  Not_Verified: "Non verificato",
  Verify: "Verificare",
  Remove: "Rimuovere",
  Verify_Successful: "Verifica riuscita",
  Verify_removed_successfully: "Verifica rimossa con successo",
  SEND_MAIL_TO_ALL_UNVERIFIED_USERS:
    "INVIA MAIL A TUTTI GLI UTENTI NON VERIFICATI",

  //PRODUCT_MODULE
  Product: "Prodotto",
  PRODUCT_EXCEL_UPLOAD: "CARICAMENTO EXCEL DEI PRODOTTI",
  Product_Details: "Dettagli del prodotto",
  Product_Image_Upload: "Caricamento delle immagini dei prodotti",
  All_Images: "Tutte le immagini",
  Upload_Single_Image: "Carica una singola immagine",
  Delete_All_Image: "Elimina tutte le immagini",
  View_Product: "Visualizza prodotto",
  TOTAL_IMAGE: "TOTALE IMMAGINI",
  Edit_Name: "Modifica nome",
  Delete: "Elimina",
  Write_Full_Product_name: "Scrivi il nome completo del prodotto",
  There_is_no_products: "Non ci sono prodotti",
  This_name_already_exists: "Questo nome esiste gia'",
  Product_Name_Required: "Nome prodotto obbligatorio",
  Image_Doest_Not_Exist: "L'immagine non esiste",
  image_name_is_required: "Il nome dell'immagine e' obbligatorio",
  SUBMIT_YOUR_ZIP_FILE: "INVIA IL TUO FILE ZIP",
  Product_Image: "Immagine dei prodotti",

  Pvp: "Pvp",
  Iva_Percent: "Percentuale Iva",
  Data_Out: "Data Out",
  Data_In: "Data In",
  Promo_Act: "Promo Attuale",
  Przlist: "Przlist",
  Exclusion_Man: "Esclusione Man",
  Um: "Um",
  Codice_Ean: "Codice Ean",
  Raggr03_Originale: "Raggr03 Originale",
  Raggr02_Originale: "Raggr02 Originale",
  Raggr01_Originale: "Raggr01 Originale",
  Totale_Smk: "Totale Smk",
  Codice: "Codice",

  dec: "Dec",
  in_stock: "In stock",
  max_value: "Valore Max",
  min_value: "Valore Min",

  active_product: "PRODOTTI ATTIVI",
  disable_product: "PRODOTTI DISABILITATI",

  product_export_failed: "Esportazione dei prodotti non riuscita",
  all_sales_points: "Tutti i punti vendita",

  //PRODUCT_INFO_CATALOGUE_MODULE
  Product_Name: "Nome del prodotto",
  Product_Code: "Codice prodotto",
  upload_at_least_one_item: "Carica almeno un elemento",
  Upload_Image_1: "Carica immagine 1",
  Upload_Image_2: "Carica immagine 2",
  Upload_Image_3: "Carica immagine 3",
  Upload_Image_4: "Carica immagine 4",
  Upload_Image_5: "Carica immagine 5",
  Upload_Video: "Carica video URL (Embedded)",
  Upload_Audio: "Carica audio URL (Embedded)",
  Code: "Codice",
  Product_Code: "Codice prodotto",
  Product_Details: "Dettagli del prodotto",
  Product_Info_updated_successfully:
    "Informazioni sul prodotto aggiornate con successo",

  //ECOMMERCE_MODULE
  Order_List: "Lista degli ordini",
  Order_Moving: "Spostamento Ordine",
  Move: "Sposta",
  Order_No: "Numero ordine",
  Order_From: "Ordine da",
  Order_Date: "Data dell'ordine",
  Delivery_Date: "Data di consegna",
  sub_total: "Totale parziale",
  Status: "Stato",
  Action: "Azione",
  CLIENT: "CLIENTE",
  Sale_Point: "Punto vendita",
  Order_No: "Numero dell'ordine",
  Order_Datetime: "Data e ora ordine",
  Order_Note: "Nota sull'ordine",
  Order_Payment: "Pagamento dell'ordine",
  CARD_PAYMENT: "PAGAMENTO CON CARTA",
  CASH_ON_DELIVERY: "PAGAMENTO ALLA CONSEGNA",
  PAYPAL_PAYMENT: "PAGAMENTO PAYPAL",
  ID: "ID",
  Name: "Nome",
  Delivery_Type: "Tipo di consegna",
  Delivery_Date: "Data di consegna",
  Delivery_Slot: "Orari di consegna",
  Change: "Modificare",
  Change_Info: "Modifica informazioni",
  Products_Chat: "Prodotti / Chat",
  CHAT_WITH_USER: "CHATTA CON L'UTENTE",
  ADD_NEW_PRODUCT: "AGGIUNGI NUOVO PRODOTTO",
  Quantity: "Quantita'",
  Note: "Nota",
  Send_Message: "Invia messaggio",
  There_is_no_new_chat_notification: "Non ci sono nuove notifiche di chat",
  Unseen_Order_List_Messages:
    "Messaggi dell'elenco degli ordini non visualizzati",
  Fails_To_Send_Message: "Impossibile inviare il messaggio",
  CONFIRM_ORDER: "CONFERMARE L'ORDINE",
  There_is_no_orders: "Non ci sono ordini",
  click_to_select_a_date: "fare clic per selezionare una data",
  Yes_Change_it: "Ok, cambialo!",
  You_can_change_it_later: "Puoi cambiarlo in seguito",
  Product_Codice_Ean_doesnt_exist_in_store:
    "Il prodotto con questo codice EAN non esiste in negozio",
  PLEASE_SELECT_DELIVERY_SLOT_TIME:
    "SI PREGA DI SELEZIONARE L'ORARIO DELLA FASCIA DI CONSEGNA",
  You_have: "Avete ",
  hand_written_products_first_remove_this_products:
    " prodotti aggiunti manualmente, rimuovere prima questo / i prodotto / i",
  Order_Details: "Dettagli dell'ordine",
  ORDER: "ORDINE",
  Choose: "Scegliere",
  Available_slot: "Slot disponibile",
  Total_Price: "Prezzo totale",
  EDIT: "MODIFICARE",
  Product_Quantity: "Quantita'ï¿½del prodotto",
  chat_message: "messaggio chat",

  Order_Total_Amount: "Totale dell'ordine",
  Shipping_Cost: "Spese di spedizione",
  Current_extra_cost: "Totale dei costi extra",
  Order_Total_Final_Amount: "Totale generale dell'ordine",
  SUBMIT_COUPONS: "APPLICA COUPONS",
  Subtotal: "SubTotale",
  Shipping: "Consegna",
  Grand_Total: "Totale finale",
  Invoice: "Scontrino",
  Order: "Ordine",
  sorry_can_not_move_order: "Spiacenti, non puoi spostare questo ordine",
  these_products_out_of_stock: "Questi prodotti sono esauriti",
  You_can_move_the_order_only_for_buying_request:
    "È possibile spostare l'ordine solo per richieste di acquisto.",
  You_can_move_the_order: "Puoi spostare l'ordine",
  Successfully_match_all_items: "Abbinati correttamente tutti gli elementi",
  EXTRA_COST: "COSTI EXTRA",
  extra_cost_validation_msg:
    "Il costo aggiuntivo deve essere inferiore all'importo totale dell'ordine, quando Sottrai",

  Product_Coupons: "Coupons per i prodotti",
  Code: "Codice",
  Percentage: "Percentuale",
  Discount_Euro: "Euro di sconto",
  Current_Amount: "Importo attuale",
  Partial_Use: "Uso parziale",

  Send_Push_Notification: "Invia Notifica Push",

  //LAST_MINUTE_OFFER_MODULE
  For_Which_Sales_Point: "Per quale punto vendita",
  PDF: "PDF",
  Organization: "Organizzazione",
  Item_Type: "Tipo di elemento",
  Offer_List: "Offer List",
  Booking_List: "Booking List",
  Product_List: "Product List",
  Last_Minute_Offer_Details: "Dettagli offerta last minute",
  There_is_no_offers: "Non ci sono offerte",
  Last_Minute_Offer_Created_Succesfully:
    "Offerta Last Minute creata con successo",
  Last_Minute_Offer_Of_This_Sale_Point_Already_Exists:
    "L'offerta Last Minute di questo punto vendita risulta presente!!",

  Sales_Points_List_Loading_Problem:
    "Problemi di caricamento della lista punti vendita",
  UPLOAD_IMAGE_PDF: "CARICA IMMAGINE / PDF",
  Last_Minute_Offer_List: "Elenco offerte last minute",
  Last_Minute_Offer_Update: "Aggiornamento dell'offerta Last Minute",

  lastMinuteOfferBookingItems: "Offerte Last Minute Booking Items",
  requestId: "ID Richiesta: ",
  back: "Indietro",
  productName: "Nome Prodotto",
  quantity: "Quantità",
  noOffers: "Nessuna offerta disponibile.",
  date: "Data",
  salesPoint: "Punto Vendita",
  totalItem: "Totale Articolo",
  total: "Totale",
  details: "Dettagli",
  lastMinuteOfferProductDetails: "Dettagli del Prodotto dell'Offerta Last Minute",

  //COMMUNICATION_MODULE
  Message_Send: "Invia messaggio",
  Message: "Messaggio",
  Message_List: "Elenco dei messaggi",
  Send_By: "Inviato da",
  YOUR_MESSAGE: "IL TUO MESSAGGIO",
  There_is_no_messages: "Non ci sono messaggi",
  Message_Update_Successfully: "Messaggio aggiornato con successo",

  Message_Update: "Aggiornamento dei messaggi",

  //INSTITUTIONAL_FLYERS
  Flyer_Starting_Date: "Data di inizio del volantino",
  Flyer_Ending_Date: "Data di fine del volantino",
  upload_flyer_image: "carica l'immagine del volantino",
  Institutional_Flyer_List: "Elenco volantini digitali",
  There_is_no_flyer_available: "Nessun volantino disponibile",
  Flyer_Has_Been_Created_Successfully: "Volantino creato con successo",
  Please_upload_the_file: "Carica il file",
  Flyer_Has_Been_Updated_Successfully: "Volantino aggiornato correttamente",

  Image: "Immagine",
  Flyer_Update: "Aggiornamento volantino",

  //SALES_POINT_MODULE
  Branch_Info_Upload: "Caricamento informazioni dei punti vendita",
  Branch_Service_Upload: "Caricamento dei servizi attivi nei punti vendita",
  Sales_Points_List: "Elenco punti vendita",
  Sales_Points: "Punti vendita",
  Zone: "Zona",
  Comune: "Comune",
  BRANCH_DETAILS: "DETTAGLI DEL PUNTO VENDITA",
  Telephone: "Telefono",
  Region: "Regione",
  BRANCH_DELIVERY_SLOT: "SLOT DI CONSEGNA DEL PUNTO VENDITA",
  HOME_DELIVERY_SLOT: "SLOT CONSEGNA A DOMICILIO",
  Order_Collect_Time: "Tempo di raccolta dell'ordine",
  Monday: "Lunedi",
  Tuesday: "martedi",
  Wednesday: "mercoledi",
  Thrusday: "Giovedi",
  Friday: "Venerdi",
  Saturday: "Sabato",
  Sunday: "Domenica",
  Order_Close_Time: "Orario di chiusura degli ordini",
  Collect_from_store_slot: "Raccogli dallo slot del negozio",
  BRANCH_SERVICES: "SERVIZI DEL PUNTO VENDITA",
  YES: "SI",
  NO: "NO",
  There_is_no_branches: "Non ci sono punti vendita",
  Service_status_is_changed: "Lo stato del servizio risulta cambiato",
  Make_sure_to_fill_all_the__red_marking_fields:
    "Assicurati di compilare tutti i campi contrassegnati in rosso (*)",
  Sale_point_updated_sucessfully: "Punto vendita aggiornato con successo",
  Sales_Points_Details: "Dettagli punti vendita",
  Respo1: "Respo1",
  Respo2: "Respo2",
  Respo3: "Respo3",
  Make_sure_to_fill_all_the_fields_with_red_sign:
    "Assicurati di riempire tutti i campi con il segno rosso (*)",
  Sales_Points_Update: "Aggiornamento punti vendita",
  select_province_first: "Seleziona prima la provincia",
  select_region_first: "Selezionare prima la regione",

  //DELIVERY_SLOT_MODULE
  Upload_sales_points_delivery_time_table:
    "Carica l'orario di consegna dei punti vendita",
  Add_Special_Date_and_Delivery_Slots_for_Stores:
    "Aggiungi date speciali e slot di consegna per i negozi",
  Select_Store_by_shop_Code: "Seleziona Negozio per codice negozio",
  None_Selected: "Nessuno selezionato",
  select_all: "Seleziona tutto",
  Special_Date: "Data speciale",
  Special_Dates: "Date speciali",
  click_to_select_date: "Fare clic per selezionare la data",
  ADD: "INSERISCI",
  SUBTRACT: "SUBTRACT",
  Home_Delivery: "Consegna a domicilio",
  Delivery_From_Store: "Consegna dal negozio",
  Home_Delivery_Slot: "Slot di consegna a domicilio",
  Store_Delivery_Slot: "Spazio di consegna in negozio",
  Home_Delivery_Same_Day_Delivery_Order_Closing_Time:
    "Consegna a domicilio Consegna lo stesso giorno Orario di chiusura dell'ordine",
  Store_Delivery_Same_Day_Delivery_Order_Closing_Time:
    "Consegna in negozio Consegna lo stesso giorno Orario di chiusura dell'ordine",

  Choose_delivery_availability_for_home_delivery_and_delivery_from_store:
    "Scegli la disponibilita di consegna per la consegna a domicilio e la consegna dal negozio",
  Please_add_one_or_multiple_dates: "Si prega di aggiungere una o piu' date",
  Please_select_one_or_multiple_store_from_your_store_list:
    "Seleziona uno o piu' negozi dall'elenco dei negozi",
  Selected_Dates: "Date selezionate",

  //TICKET_MANAGEMENT_MODULE
  Ticket_Number: "Numero del ticket",
  Phone_Number: "Numero di telefono",
  Category: "Categoria",
  OPEN: "APERTO",
  CLOSE: "CHIUSO",
  Ticket_Created: "Ticket creato",
  Contact_No: "Contatto n.",
  Ticket_Messages: "Messaggi da ticket",
  ADMIN: "Amministratore",
  USER: "UTENTE",
  Reply: "Rispondere",
  There_is_no_tickets: "Non ci sono tickets",
  Ticket_doesnt_exist: "Il ticket non esiste",
  Tickets_List: "Elenco dei tickets",
  Ticket_Number_is_required: "Il numero del ticket e' obbligatorio",
  Tickets_Details: "Dettagli dei tickets",
  TICKET: "TICKET",
  Tickets: "Tickets",
  Tickets_Categories: "Categorie dei tickets",
  Category_ID: "ID categoria",
  Ticket_Category_Update: "Categoria ticket aggiornata",
  Ticket_Category_Create: "Categoria ticket creata",

  //NEWS_MODULE
  News_Create: "Crea post",
  News_Title: "Titolo della notizia",
  News_Description: "Descrizione delle notizie",
  Upload_News_Image: "Carica immagine notizie",
  News_List: "Elenco delle notizie",
  News_Image: "Immagine di notizie",
  News_Details: "Dettagli notizie",
  News_Has_Been_Created_Successfully:
    "Le notizie sono state create con successo",
  There_is_no_news: "Non ci sono novita'",
  News_Has_Been_Updated_Successfully:
    "Le notizie sono state aggiornate con successo",
  new_description_required: "Descrizione della notizia",
  News_Update: "Aggiornamento delle notizie",

  // USER_POINT MODULE
  User_Points_List: "Lista dei Punti Elettronici utente",
  Total_Earning_Points: "Punti Elettronici totali",
  Current_Points: "Punti Elettronici correnti",
  Pending_Points: "Punti Elettronici in sospeso",
  Approved_Points: "Punti Elettronici approvati",
  Rejected_Points: "Punti Elettronici rifiutati",
  User_Points_Details: "Dettagli Punti Elettronici utente",
  target_points: "Punti Elettronici ad Obiettivo",
  referral_points_list: "Lista dei punti per presentazione amici",
  referral_points_details:
    "Dettaglio dei Punti Elettronici per amici presentati",
  Invitation_Points: "Punti Elettronici per Inviti",
  Use_Referral_Code: "Utilizzi del codice di presentazione",
  Accepting_Points: "Accettazione punti",
  Referred_Users: "Utenti referenziati",
  Start_date_must_be_less_then_end_date:
    "La data di inizio deve essere inferiore alla data di fine",

  Points_Conversion_List: "Elenco conversione Punti Elettronici",
  Points_Conversion_Requests: "Richieste di Conversione Punti Elettronici",
  Points_To_Coupon: "da Punti Elettronici a Coupon",
  Terms_and_conditions: "Termini e condizioni",
  Generation: "Order Refer Generation",
  Referral_Points: "Referral App Download",
  Generation_label: "Generation label",
  Earning_Points: "Earning Points",
  generation_points_details: "Generation Points Details",

  // all status
  Earned_From_Order: "Guadagnati dagli acquisti fatti",
  Coupons_Request_Pending: "Richiesta coupon in sospeso",
  Coupons_Request_Approved: "Richiesta coupon approvata",
  Coupons_Request_Rejected: "Richiesta coupon rifiutata",
  Approved: "Approvata",
  Rejected: "Respinto",
  Pending: "In attesa di",
  None: "Nessuno",

  // button label
  Approve: "Approvare",
  Reject: "Rifiutare",
  Approve_All: "Approva tutto",
  Reject_All: "Rifiutare tutto",

  // msg
  Approved_All_Request: "Tutte le richieste approvate",
  Rejected_All_Request: "Richiesta rifiutata",
  Approved_Request: "Richiesta approvata",
  Rejected_Request: "Richiesta respinta",

  // placeholder
  Select_Status: "Selelezionare lo stato",
  Select_Start_Date: "Seleziona Data di inizio",
  Select_End_Date: "Seleziona Data di fine",

  // label
  Points: "Punti Elettronici",
  Date: "Data",
  Requested_Points: "Punti Elettronici richiesti",
  Total_Request: "Richiesta totale",
  User_ID: "ID utente",

  //EVENT_MODULE
  Event_Title: "Titolo dell'evento",
  Event_Description: "Descrizione dell'evento",
  Event_Starting_Date: "Data inizio evento",
  Event_Ending_Date: "Data di fine dell'evento",
  Event_Location: "Luogo dell'evento",
  Upload_Event_Image: "Carica l'immagine dell'evento",
  Event_List: "Elenco eventi",
  Event_Image: "Immagine dell'evento",
  Event_Start: "Inizio evento",
  Event_End: "Fine evento",
  Upcoming_Event: "Prossimo evento",
  Ongoing_Event: "Evento in corso",
  Event_Details: "Dettagli dell'evento`",
  There_is_no_event_of_your_seleted_type:
    "Non ci sono eventi del tipo selezionato",
  ALL: "TUTTI",
  ONGOING: "IN CORSO",
  UPCOMING: "PROSSIMAMENTE",
  Event_Create: "Crea evento",
  Event_Update: "Aggiornamento evento",
  Event_Type: "Event Type",
  Free: "Free",
  Paid: "Paid",
  Event_Ticket_Price_Amount: "Event Ticket Price Amount",
  Payment_Method: "Payment Method",
  Cash: "Cash",
  Online_Payment: "Online Payment",
  Both: "Both",
  Please_select_event_type: "Please select event type",
  Please_select_payment_method: "Please select payment method",

  //REVIEWS_MODULE
  Review_Create: "Crea gruppo di recensione per gli utenti",
  REVIEW_SET_NAME: "NOME DEL GRUPPO DI RECENSIONE",
  Your_Questions: "Le tue domande",
  write_your_question_here: "Scrivi qui la tua domanda",
  Reviews_List: "Elenco recensioni",
  Review_Set_Name: "Rivedi il nome dell'insieme",
  Review_Details: "Rivedi i dettagli",
  Reviews_Update: "Aggiornamento delle recensioni",
  If_delete_this_review_set_then_your_questions_with_rating_will_also_be_deleted_and_wont_be_able_to_revert_this:
    "Se si elimina questo set di recensioni, verranno eliminate anche le domande con valutazione e non sara' possibile ripristinarle!",
  At_a_time_you_can_active_deactive_one_review_set_and_other_review_sets_will_be_deactivated:
    "Alla volta e' possibile attivare / disattivare un set di revisione e gli altri set di revisione verranno disattivati",
  users_rate_this_question: "Gli utenti che hanno valutato questa domanda",
  not_yet: "Non ancora",
  Review_Questions_Has_Been_Created_Successfully:
    "Le domande di revisione sono state create correttamente",
  Review_Questions_Has_Been_Updated_Successfully:
    "Le domande di revisione sono state aggiornate correttamente",

  //ADVANCE_PROFILING_MODULE
  SURVEY: "SONDAGGIO",
  SURVEY_SET_NAME: "NOME IMPOSTATO SONDAGGIO",
  Survey_Set_List: "Elenco dei set di sondaggi",
  Set_Name: "Imposta nome",
  Create_Date: "Crea data",
  Last_Edited_Date: "Data ultima modifica",
  TOTAL_PARTICIAPTION: "PARTECIPAZIONE TOTALE",
  QUESTIONS: "DOMANDE",
  SURVEY_QUESTIONS: "DOMANDE DELL'INDAGINE",
  SURVEY_QUESTIONS_TYPE: "TIPO DI DOMANDE DEL SONDAGGIO",
  Single: "Singole",
  Multiple: "Molteplici",
  survey_options: "Opzioni del sondaggio",
  VIEW_ALL_QUESTIONS: "VISUALIZZA TUTTE LE DOMANDE",
  UPDATE_SET_NAME: "AGGIORNARE IL NOME IMPOSTATO",
  UPDATE_YOUR_QUESTIONS: "AGGIORNA LE TUE DOMANDE",
  DELETE_QUESTION_NO: "ELIMINA LA DOMANDA N.",
  Update_Question: "Aggiorna domanda",
  maximum_4_options: "Massimo 4 opzioni",
  All_survey_questions_and_informations_will_be_removed_and_you_wont_be_able_to_revert_this:
    "Tutte le domande e le informazioni del sondaggio verranno rimosse e non sarai in grado di annullarle!",
  At_a_time_you_can_active_deactive_one_survey_set_and_other_survey_sets_will_be_deactivated:
    "In un momento e' possibile attivare / disattivare un set di sondaggi e altri set di sondaggi verranno disattivati",
  There_is_no_survey_question_set:
    "Non e' stata impostata alcuna domanda del sondaggio",
  SET_NAME_EMPTY: "Nome del SET vuoto",

  users: "utenti",
  All_questions_and_options_will_be_removed_and_you_wont_be_able_to_revert_this:
    "Tutte le domande e le opzioni verranno rimosse e non potrai ripristinarle",
  Question_No: "Domanda n",
  Active_Inactive: "Attivo/Inattivo",
  Inactive: "Inattivo",
  Active_Survey: "Sondaggio Attivo",
  No_survey_is_active: "Non ci sono sondaggi attivi",
  Answer_Type: "Tipo di risposta",
  Create_Question: "Crea domanda",
  View_Questions: "Visualizza domande",
  TOTAL_QUESTION: "DOMANDE TOTALI",
  VIEW_ALL_PARTICIPANTS: "Visualizza tutti i partecipanti",
  Create_New_Question: "Crea nuova domanda",
  Answers: "Risposte",
  Reward_Points: "Punti Elettronici premio",
  Target_Amount: "Importo dell'obiettivo",
  All_Participants: "Tutti i partecipanti",
  back_to_the_survey_list: "Torna all'elenco dei sondaggi",

  //NOTICE_MODULE
  Notice: "Avviso allo start",
  Last_update: "Ultimo aggiornamento",
  published_by: "Pubblicato da",
  delete: "Elimina",
  update_notice: "Aggiornamento dell'avviso",
  Notice_Sent_Successfully: "Avviso inviato con successo",

  //CONTACT_MODULE
  Contact_Details: " Dettagli del contatto",
  Head_Office_Contact: "Contatto della sede centrale",
  Customer_Care_Contact: "Contatto per l'assistenza clienti",
  Complaints_Center_Contact: "Contatto Centro reclami",
  Information_Center_Contact: "Contatto del centro informazioni",
  ADD_CONTACT: "AGGIUNGI CONTATTO",
  Contact_Address: "Indirizzo di contatto",
  Fax: "Fax",
  Facebook_URL: "URL di Facebook",
  Instagram_URL: "URL di Instagram",
  Twitter_URL: "URL di Twitter",
  linkedin_url: "Url linkedin",

  Create_New_Contact_for: "Crea nuovo contatto per",

  //PRIVACY_AGREEMENT_MODULE
  Privacy_Agreement: "Accordo sulla privacy",
  Privacy_Text: "Testo sulla privacy",
  Update_Privacy: "Aggiorna privacy",
  Please_Write_Your_Privacy: "Scrivi la tua privacy",
  user_list: "Elenco degli utenti accettati",
  Privacy_List: "Elenco privacy",
  active_privacy: "Informativa sulla privacy attiva",
  deactivated_privacy: "Informativa sulla privacy disattivata",

  //MANAGE_USER_ACCOUNT_MODULE
  Users_Account_Delete_Requests:
    "Richieste di cancellazione dell'account utente",

  //SETTINGS_MODULE
  SETTINGS_MODULE: "MODULO IMPOSTAZIONI",
  Max_Item_For_Shopping_List:
    "Numero massimo di articoli per lista della spesa",
  Max_Item_For_Favourite_List:
    "Numero massimo di articoli per elenco preferiti",
  Max_Item_For_Essential_List:
    "Numero massimo di articoli per elenco essenziale",

  Settings: "Impostazioni",

  paypal_client_id: "Paypal Identificativo cliente",
  paypal_secret_key: "Paypal Chiave segreta",
  stripe_private_key: "Chiave privata Stripe",
  stripe_publishable_key: "Chiave pubblica Stripe",
  change_paypal_key: "Cambia chiave di pagamento Paypal",
  change_stripe_key: "Cambia chiave di pagamento Stripe",

  Coupon_Conversion_Rate: "Tasso di conversione coupon",
  Max_Min_Request: "Richiesta massima / minima",
  Expiry_Date: "Giorni di scadenza",

  Maximum_Point: "Max Punti Elettronici",
  Maximum_Euro: "Max Euro",
  Minimum_Point: "Min Punti Elettronici",
  Minimum_Euro: "Min Euro",
  No_Max_Limit: "Nessun limite massimo",
  No_Min_Limit: "Nessun limite minimo",
  No_Days_Limit: "Nessuna limitazione di giorni",

  Point: "Punti Elettronici",
  Euro: "Euro",
  Days: "Giorni",

  create_new_user: "Crea nuovo utente",
  Create_New_Branch_User: "Crea nuovo utente filiale",
  Update_Branch_User: "Aggiorna utente filiale",
  Change_User_Password: "Modifica password utente",
  Password_changed_successfully: "Password cambiata con successo",
  Password_dose_not_match: "Dose di password non corrispondente",
  User_update_successfully: "Aggiornamento utente riuscito",
  Username_already_exist:
    "Il nome utente e' gia' esistente. Si prega di provare un altro nome utente",
  branch_code: "Codice della filiale",

  product_attributes: "Caratteristiche del prodotto",
  attributes: "attributo",

  referral_points: "Punti per presentazione amici",
  Reward_points_for_sending_invitation: "Punti premio per l'invio di inviti",
  Reward_points_for_using_referral_code: "Punti premio per l'utilizzo del codice di presentazione",
  
  //ABOUT_MODULE
  About_Us: "Chi siamo",
  Our_Team: "Il nostro Team",
  List: "Lista",
  Designation: "Ruolo",
  View_Position: "Vedi posizione",
  No_Team_Members: "Nessun membro del Team",
  Create_Team_Member: "Crea membro del Team",
  Edit_Team_Member: "Edita membro del Team",
  Upload_Image: "Carica Immagine",

  Blog: "Blog",
  No_Blog_Found: "Nessun Blog",
  Edit_Blog: "Modifica notizia nel Blog",
  Upload_New_Blog: "Carica una nuova notizia nel Blog",
  Upload_Image_Video: "Carica Image/Video",
  OR: "O",
  Embed_Video_Link: "Incorpora un Video Link",
  Paste_your_video_link_here: "Incolla il tuo video link quì:",
  Gallery: "Galleria",
  No_Gallery_Item_Found: "Nessuna immagine in Galleria",
  Edit_Item: "Modifica Item",
  Upload_New_Item: "Carica Nuovo Item",
  Title: "Titolo",
  
  //QUICK CHAT
  Quick_Chat: "Chat Veloce",
  Chat: "Chat",
  Send: "Invia",
  Customer_List: "Lista cliente",
  New: "Nuova",
  min_ago: "min fa",
  hrs_ago: "ore fa",
  days_ago: "giorni fa",
  months_ago: "mesi fa",
  years_ago: "anni fa",

  Delivery_Address: "Indirizzo di spedizione",
  Address_1: "Indirizzo 1",
  Address_2: "Indirizzo 2",
  No_Delivery_Address_Found: "Nessun indirizzo trovato",

  Total_Items: "Totale pezzi",

  //HELP & VARIOUS
  help_and_various: "Help & Varie",
  module_name: "Nome del Modulo",
  tutorial: "Tutorial",

  //APP VIDEO TOUR
  app_video_tour: "Tour virtuali",
  iframe_link: "Collegamento iframe",

  //Coupons
  coupons: "Coupons",
  coupon_upload: "Carica coupons",
  coupon_list: "Elenco coupons",
  COUPONS_UPLOAD: "CARICA COUPONS",
  coupon_search: "Ricerca Coupon",
  coupon_id_fidelity: "ID Fidelilty",
  coupon_prodotto: "Prodotto",
  coupons_reparto: "Reparto ",
  coupon_sottoreparto: "Sottoreparto",
  coupon_Codice_EAN: "Codice EAN",
  coupon_Disc_percentage: "Sconto_%",
  coupon_Disc_euro: "Sconto_Eur",
  coupons_expiration: "Scadenza",
  coupons_used: "Usato",
  There_are_no_coupons: "Non ci sono coupon",
  reset_all_coupons: "Reimposta tutti i coupon",
  all_coupons_reset_alert:
    "Questa azione non puo' essere annullata. Sei sicuro di voler ripristinare tutti i coupon?",
  export_coupons_before_reset_alert:
    "Vuoi esportare l'elenco dei coupon correnti prima di reimpostare tutti i coupon?",
  yes_reset_all: "Si, resetta tutto",
  reset: "resetta tutto",
  Select_Department: "Seleziona Reparto",
  Select_Sub_Department: "Seleziona Sottoreparto",
  Select_Expiration_Date: "Seleziona Data di scadenza",
  barcode: "Barcode",

  //Delivery Cost
  deliver_cost: "Spese di Spedizione",
  free: "Gratis",
  delivery_cost_free_for_order_amount:
    "Spese di spedizione gratuite per l'importo dell'ordine",
  fixed: "Prezzo fisso",
  percentage: "Percentuale",
  select_one_delivery_cost_value:
    "Non hai selezionato il costo di spedizione !",
  shipping_cost_validation_msg:
    "Seleziona una o entrambe le opzioni da COSTO DI SPEDIZIONE FISSO o COSTO DI SPEDIZIONE PERCENTUALE. Sarà applicabile quando il prezzo dell'ordine sarà inferiore all'importo minimo del prezzo dell'ordine.",
  Common_Shipping_Cost: "Costo di spedizione comune a tutti",
  Shipping_cost_free_for_minimum_order_amount:
    "Spese di spedizione gratuite per un importo minimo di ordine",
  Upload: "Caricamento",
  Uploading: "Sto caricando",
  file_upload_successful: "File caricato con successo",
  update_successful: "Aggiornamento completato",

  //Shopping Points
  shopping_points: "Punti Elettronici per lo Shopping",
  shopping_points_amount:
    "Importo minimo per ricevere un numero di Punti Elettronici pari a...",
  shopping_points_total: "Punti Elettronici",

  //HOME_MODULE
  TOTAL_APP_USERS: "UTENTI TOTALI DELL'APP",
  ANDROID_IOS: "ANDROID e IOS",
  FIDELITY_CARD_REQUEST: "RICHIESTA CARTE FEDELTA'",
  today: "oggi",
  ORDER_RECEIVED: "ORDINI RICEVUTI",
  last_7_days: "ultimi 7 giorni",
  FIND_PRODUCT_INFO: "TROVA LE INFORMAZIONI SUL PRODOTTO",
  FIND_ORDER_INFO: "TROVA LE INFORMAZIONI PER L'ORDINE",
  CUSTOMER_ANALYSIS: "Analisi del cliente",
  PRODUCT_SALES: "VENDITE DI PRODOTTI",
  Sales_for: "Vendite per",
  TOP_5_PRODUCTS: "I 5 PRODOTTI MIGLIORI",
  based_on_selling: "basato sulla vendita",
  APP_DOWNLOADED: "APP SCARICATE",
  new_user_for: "nuovo utente per",
  family_memeber: "MEMBRI DELLA FAMIGLIA",
  Jan: "Gen",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "Mag",
  Jun: "Giu",
  Jul: "Lug",
  Aug: "Ago",
  Sep: "Set",
  Oct: "Ott",
  Nov: "Nov",
  Dec: "Dic",

  TOTAL_QUESTIONS_PER_REVIEW_SET: "TOTALE DOMANDE PER GRUPPO DI RECENSIONI",
  TOTAL_QUESTIONS_PER_ADVANCE_PROFILING_SET:
    "TOTALE DOMANDE PER GRUPPO DI PROFILAZIONE ANTICIPATA",
  REPARTO_ORIGINALE: "REPARTO ORIGINALE",
  PROMO_ACT: "PROMO ATTUALE",
  SOTTOREPARTO_ORIGINALE: "SOTTOREPARTO ORIGINALE",
  TOTAL_ORDERS: "ORDINI TOTALI",
  TOTAL_VALUES_OF_ORDERS: "AMMONTARE TOTALE DEGLI ORDINI",
  CASH_ON_DELIVERY: "PAGAMENTO ALLA CONSEGNA",
  CARD_PAYMENT: "PAGAMENTO CON CARTA",
  PAYPAL_PAYMENT: "PAGAMENTO PAYPAL",
  HOME_DELIVERY: "CONSEGNA A DOMICILIO",
  COLLECT_FROM_STORE: "RITIRO DAL NEGOZIO",
  TOTAL_ORDER_ON_STATUS: "TOTALE DELL'ORDINE PER TIPOLOGIA DI STATO",
  TOTAL_ORDER_DELIVERED_PER_MONTH: "TOTALE ORDINI CONSEGNATO PER MESE",
  TOTAL_ORDER_DELIVERED_PER_DAY: "TOTALE ORDINI CONSEGNATO PER GIORNO",
  TOTAL_VALUE_OF_ORDER_DELIVERED_PER_MONTH:
    "VALORE TOTALE DEGLI ORDINI CONSEGNATI PER MESE",
  TOTAL_ORDER_VALUE_DELIVERED_PER_MONTH:
    "VALORE TOTALE DEGLI ORDINI CONSEGNATI PER MESE",
  TOTAL_ORDER_VALUE_DELIVERED_PER_YEAR:
    "VALORE TOTALE DEGLI ORDINI CONSEGNATI PER ANNO",
  TOTAL_ORDER_VALUE_DELIVERED_PER_DAY:
    "VALORE TOTALE DEGLI ORDINI CONSEGNATI PER GIORNO",
  TOTAL_VALUE: "TOTALE VALORE COMPLESSIVO",
  TOTAL_ITEM_PROMO_ACT_USED: "TOTALE ARTICOLO PROMO ATTUALE UTILIZZATO",
  TOTAL_ITEM_COUPON_USED: "TOTALE ARTICOLO COUPON UTILIZZATO",
  TOTAL_ORDER_PER_ITEM_NO: "TOTALE ARTICOLI PER ORDINE",
  TOTAL_ORDER_PER_SLOT_home_delivery:
    "TOTALE ORDINI PER SLOT (consegna a domicilio)",
  TOTAL_ORDER_PER_SLOT_store_delivery:
    "TOTALE ORDINI PER SLOT (consegna in negozio)",

  MALE: "MASCHIO",
  FEMALE: "FEMMINA",
  Select_Year: "Seleziona anno",
  Select_Month: "Selezionare il mese",
  Select_province_list: "Seleziona l'elenco delle province",
  Select_sales_point: "Seleziona punti vendita",
  DATA: "DATI",

  //Copywright
  Copyright_eCommerceVolution:
    "Copyright eCommerceVolution by Big Data Innovation Group 2020/2024. Tutti i diritti sono riservati",

  //banners
  slider: "Slider",
  banners: "Banners della pagina di start",
  have_to_upload_slider:
    "devi caricare un'immagine del dispositivo di scorrimento",

  //category
  category: "Categorie",
  select_category: "Seleziona categoria",
  serial_no: "No.",
  upload: "Carica/Cambia",
  no_category: "Non esiste una categoria",

  Shipping_cost: "Spese di spedizione",
  for_minimum_order_amount: "per un ordine con importo minimo ",
  Shipping_Cost_for_province: "Spese di spedizione per comune",
  Excel_File_Header_Mismatched:
    "Intestazione del file Excel non corrispondente",
  Add_new_shipping_cost: "Aggiungi nuovo costo di spedizione",
  Update_shipping_Cost: "Aggiorna costo di spedizione",
  Amount_nearest_Euro: "Importo (all'Euro più vicino)",
  shop_code: "Codice negozio",
  shipping_cost: "Spese di spedizione",

  product_points: "Punti Elettronici prodotto",
  product_points_list: "Elenco dei Punti Elettronici per prodotto",
  Product_Points_Update: "Aggiornamento Punti Elettronici prodotto",

  Ticket_Category_Create: "Crea Categoria per i Ticket",

  Points_By_Referring: "Punti Elettronici da Presentazioni",
  Self_Installed_Points: "Punti Elettronici per installazione",
  Total_Points: "Totale Punti Elettronici",
  Total_Installed_By_Refer_Code:
    "Totale Installazioni tramite Codice di Presentazione",

  earn_from_survey: "Punti Elettronici guadagnati da sondaggi",
  earn_from_product: "Punti Elettronici guadagnati da prodotti acquistati",
  earn_from_referral_invitation:
    "Punti Elettronici guadagnati da presentazioni",
  earn_from_using_referral_code:
    "Punti Elettronici guadagnati da Installazione APP",
  earn_from_target_shopping:
    "Punti Elettronici guadagnati da obiettivi ai clienti",

  //MLM
  mlm: "MLM",
  refer_conversion_point: "Fare riferimento al punto di conversione",
  first_refer: "1° Riferimento",
  order_per_amount: "Importo per Ordine",
  second_refer: "2° Riferimento",
  third_refer: "3° Riferimento",

  firstReferRequired: "Il primo riferimento deve avere valori.",
  both_fields_must_be_filled: "Entrambi i campi devono essere compilati.",
  fillPrevious: "Devi compilare il riferimento precedente prima di questo.",

};
