import { lazy } from 'react';
import Roles from '../../common/Roles';

const SalesPointUploadType = lazy(() => import('../../pages/sales_points/SalesPointUploadType'));
const SalesPointsList = lazy(() => import('../../pages/sales_points/SalesPointsList'));
const SalesPointDetails = lazy(() => import('../../pages/sales_points/SalesPointsDetails'));
const SalesPointsUpdate = lazy(() => import('../../pages/sales_points/SalesPointsUpdate'));


export const SalesPointsRoutes = [
  {
    path: '/sales_points/upload',
    exact: true,
    component: SalesPointUploadType,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/sales_points/list',
    exact: true,
    component: SalesPointsList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/sales_points/:id/details',
    exact: true,
    component: SalesPointDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/sales_points/:id/edit',
    exact: true,
    component: SalesPointsUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
