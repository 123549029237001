import { lazy } from 'react';
import Roles from '../../common/Roles';

const Teams = lazy(() => import('../../pages/about_us/Teams'));
const TeamMemberCreate = lazy(() => import('../../pages/about_us/TeamMemberCreate'));
const Blogs = lazy(() => import('../../pages/about_us/Blogs'));
const CreateBlog = lazy(() => import('../../pages/about_us/BlogCreate'));
const Gallery = lazy(() => import('../../pages/about_us/Gallery'));
const GalleryItemCreate = lazy(() => import('../../pages/about_us/GalleryItemCreate'));


export const AboutUsRoutes = [
  {
    path: '/team',
    exact: true,
    component: Teams,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/team/create',
    exact: true,
    component: TeamMemberCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/team/edit/:id',
    exact: true,
    component: TeamMemberCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/blog',
    exact: true,
    component: Blogs,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/blog/create',
    exact: true,
    component: CreateBlog,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/blog/edit/:id',
    exact: true,
    component: CreateBlog,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/gallery',
    exact: true,
    component: Gallery,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/gallery/create',
    exact: true,
    component: GalleryItemCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/gallery/edit/:id',
    exact: true,
    component: GalleryItemCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
