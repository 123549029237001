import { lazy } from 'react';
import Roles from '../../common/Roles';

const MLM = lazy(() => import('../../pages/mlm/MLM'));

export const MLMRoutes = [
  {
    path: '/mlm',
    exact: true,
    component: MLM,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
