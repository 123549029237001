import { lazy } from 'react';
import Roles from '../../common/Roles';



const NewsCreate = lazy(() => import('../../pages/news/NewsCreate'));
const NewsList = lazy(() => import('../../pages/news/NewsList'));
const NewsDetails = lazy(() => import('../../pages/news/NewsDetails'));
const NewsUpdate = lazy(() => import('../../pages/news/NewsUpdate'));


//

export const NewsRoutes = [
  {
    path: '/news/send',
    exact: true,
    component: NewsCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/news/list',
    exact: true,
    component: NewsList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/news/:id/details',
    exact: true,
    component: NewsDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/news/:id/edit',
    exact: true,
    component: NewsUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
