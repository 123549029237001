import LN from '../../common/Language'
import Roles from '../../common/Roles'

export const side_bar_data = (Lang) => [
  //Home
  {
    route: '/home',
    label: LN[Lang].home,
    permission: [Roles.admin, Roles.branch_user],
    child: [],
    childShouldActive: ['/product/graph', '/order/graph', '/customer/graph']
  },

  //Fidelity Card Request
  {
    route: '/fidelity/card/requests',
    label: LN[Lang].fidelity_card_request,
    permission: [Roles.admin],
    child: [],
    childShouldActive: ['/fidelity/card/requests/:id/details', '/fidelity/card/requests/new']
  },

  //Fidelity Card
  {
    route: null,
    label: LN[Lang].fidelity_card,
    permission: [Roles.admin, Roles.branch_user],
    child: [
      {
        route: '/fidelity/card/upload',
        label: LN[Lang].fidelity_card_upload,
        permission: [Roles.admin],
      },
      {
        route: '/fidelity/card/list',
        label: LN[Lang].fidelity_card_list,
        permission: [Roles.admin, Roles.branch_user],
        childShouldActive: ['/fidelity/card/:id/details']
      },
    ],
  },

  //Image Management
  {
    route: null,
    label: LN[Lang].image_management,
    permission: [Roles.admin],
    child: [
      {
        route: '/categories',
        label: LN[Lang].category,
        permission: [Roles.admin],
        childShouldActive: ['/categories']
      },
      {
        route: '/notice',
        label: LN[Lang].notice,
        permission: [Roles.admin],
      },
      {
        route: '/banners/settings',
        label: LN[Lang].banners,
        permission: [Roles.admin],
      },
      // {
      //   route: '/product/image/list',
      //   label: LN[Lang].product_image_list,
      //   permission: [Roles.admin],
      // },
    ],
  },

  //Category
  // {
  //   route: '/categories',
  //   label: LN[Lang].category,
  //   permission: [Roles.admin],
  //   child: [],
  //   childShouldActive: ['/categories']
  // },

  //Product
  {
    route: null,
    label: LN[Lang].product,
    permission: [Roles.admin, Roles.branch_user],
    child: [
      {
        route: '/product/upload',
        label: LN[Lang].product_excel_upload,
        permission: [Roles.admin],
      },
      {
        route: '/product/list',
        label: LN[Lang].product_list,
        permission: [Roles.admin, Roles.branch_user],
        childShouldActive: ['/product/:id/details', '/product/:id/edit']
      },
      {
        route: '/product/image/upload',
        label: LN[Lang].product_image_upload,
        permission: [Roles.admin],
      },
      {
        route: '/product/image/list',
        label: LN[Lang].product_image_list,
        permission: [Roles.admin],
      },
    ],
  },

  //Product Info
  {
    route: null,
    label: LN[Lang].product_info,
    permission: [Roles.admin, Roles.branch_user],
    child: [
      {
        route: '/product/info/create',
        label: LN[Lang].product_info_create,
        permission: [Roles.admin],
      },
      {
        route: '/product/info/list',
        label: LN[Lang].product_info_list,
        permission: [Roles.admin, Roles.branch_user],
        childShouldActive: ['/product/info/:id/details', '/product/info/:id/edit']
      },
    ],
  },

  //Product Orders
  {
    route: '/product/order/list',
    label: LN[Lang].product_orders,
    permission: [Roles.admin, Roles.branch_user],
    child: [],
    childShouldActive: ['/product/order/:id/details', '/product/order/list/notifications', '/product/order/:id/OrderDetailsPrint']
  },

  //Last Minute Offer
  {
    route: null,
    label: LN[Lang].last_minute_offer,
    permission: [Roles.admin, Roles.branch_user],
    child: [
      {
        route: '/lastminute/offer/create',
        label: LN[Lang].offer_create,
        permission: [Roles.admin],
      },
      {
        route: '/lastminute/offer/list',
        label: LN[Lang].offer_list,
        permission: [Roles.admin, Roles.branch_user],
        childShouldActive: ['/lastminute/offer/:id/details', '/lastminute/offer/:id/edit']
      },
    ],
  },

  //Coupons
  {
    route: null,
    label: LN[Lang].coupons,
    permission: [Roles.admin, Roles.branch_user],
    child: [
      {
        route: '/coupon/coupon_upload',
        label: LN[Lang].coupon_upload,
        permission: [Roles.admin],
      },
      {
        route: '/coupon/coupon_list',
        label: LN[Lang].coupon_list,
        permission: [Roles.admin, Roles.branch_user],
      },
    ],
  },

  //User Points
  {
    route: null,
    label: LN[Lang].user_points,
    permission: [Roles.admin],
    child: [
      {
        route: '/users/points_list',
        label: LN[Lang].points_list,
        permission: [Roles.admin],
        childShouldActive: ['/user/:id/point_details']
      },
      {
        route: '/users/points_conversion_list',
        label: LN[Lang].points_conversion_list,
        permission: [Roles.admin],
      },
      {
        route: '/users/points_requests',
        label: LN[Lang].points_conversion_requests,
        permission: [Roles.admin],
      },
      {
        route: '/users/export_user_points',
        label: LN[Lang].export_user_points,
        permission: [Roles.admin],
      },
      {
        route: '/users/target_points',
        label: LN[Lang].target_points,
        permission: [Roles.admin],
      },
      {
        route: '/users/referral_points_list',
        label: LN[Lang].referral_points_list,
        permission: [Roles.admin],
        childShouldActive: ['/users/:id/referral_points_details', '/users/generation_points_list']
      },
      {
        route: '/settings/product_points',
        label: LN[Lang].product_points,
        permission: [Roles.admin],
        childShouldActive: ['/settings/product_points/:id/edit']
      },
    ],
  },

  //Communication
  {
    route: null,
    label: LN[Lang].communication,
    permission: [Roles.admin],
    child: [
      {
        route: '/message/send',
        label: LN[Lang].send_message,
        permission: [Roles.admin],
      },
      {
        route: '/message/list',
        label: LN[Lang].message_list,
        permission: [Roles.admin],
        childShouldActive: ['/message/:id/details', '/message/:id/edit']
      },
    ],
  },

  //Institutional Flyers
  {
    route: null,
    label: LN[Lang].institutional_flyers,
    permission: [Roles.admin],
    child: [
      {
        route: '/flyer/create',
        label: LN[Lang].create_flyer,
        permission: [Roles.admin],
      },
      {
        route: '/flyer/list',
        label: LN[Lang].flyers_list,
        permission: [Roles.admin],
        childShouldActive: ['/flyer/:id/edit']
      },
    ],
  },

  //Sales Points
  {
    route: null,
    label: LN[Lang].sales_points,
    permission: [Roles.admin],
    child: [
      {
        route: '/sales_points/upload',
        label: LN[Lang].upload_sales_point_info,
        permission: [Roles.admin],
      },
      {
        route: '/sales_points/list',
        label: LN[Lang].sales_point_list,
        permission: [Roles.admin],
        childShouldActive: ['/sales_points/:id/details', '/sales_points/:id/edit']
      },
      {
        route: '/delivery_slot',
        label: LN[Lang].sales_points_delivery_slot,
        permission: [Roles.admin, Roles.branch_user],
      },
      {
        route: '/create_delivery_slot',
        label: LN[Lang].upload_delivery_slots,
        permission: [Roles.admin],
      },
      {
        route: '/special_delivery_dates',
        label: LN[Lang].special_delivery_dates,
        permission: [Roles.admin],
      },
    ],
  },

  //Delivery Slot
  // {
  //   route: null,
  //   label: LN[Lang].delivery_slot,
  //   permission: [Roles.admin, Roles.branch_user],
  //   child: [
  //     {
  //       route: '/delivery_slot',
  //       label: LN[Lang].sales_points_delivery_slot,
  //       permission: [Roles.admin, Roles.branch_user],
  //     },
  //     {
  //       route: '/create_delivery_slot',
  //       label: LN[Lang].upload_delivery_slots,
  //       permission: [Roles.admin],
  //     },
  //     {
  //       route: '/special_delivery_dates',
  //       label: LN[Lang].special_delivery_dates,
  //       permission: [Roles.admin],
  //     },
  //   ],
  // },

  //Ticket Management
  {
    route: null,
    label: LN[Lang].ticket_management,
    permission: [Roles.admin],
    child: [
      {
        route: '/tickets/list',
        label: LN[Lang].Tickets,
        permission: [Roles.admin],
        childShouldActive: ['/tickets/:id/details', '/tickets/notifications']
      },
      {
        route: '/tickets/categories',
        label: LN[Lang].Tickets_Categories,
        permission: [Roles.admin],
        childShouldActive: []
      },
    ],
  },

  //News
  {
    route: null,
    label: LN[Lang].news,
    permission: [Roles.admin],
    child: [
      {
        route: '/news/send',
        label: LN[Lang].create_news,
        permission: [Roles.admin],
      },
      {
        route: '/news/list',
        label: LN[Lang].news_list,
        permission: [Roles.admin],
        childShouldActive: ['/news/:id/details', '/news/:id/edit']
      },
    ],
  },

  //Events
  {
    route: null,
    label: LN[Lang].events,
    permission: [Roles.admin],
    child: [
      {
        route: '/event/create',
        label: LN[Lang].create_event,
        permission: [Roles.admin],
      },
      {
        route: '/event/list',
        label: LN[Lang].event_list,
        permission: [Roles.admin],
        childShouldActive: ['/event/:id/details', '/event/:id/edit', '/event/:id/booking']
      },
    ],
  },

  //Reviews
  {
    route: null,
    label: LN[Lang].reviews,
    permission: [Roles.admin],
    child: [
      {
        route: '/reviews/create',
        label: LN[Lang].create_reviews,
        permission: [Roles.admin],
      },
      {
        route: '/reviews/list',
        label: LN[Lang].reviews_list,
        permission: [Roles.admin],
        childShouldActive: ['/reviews/:id/details', '/reviews/:id/edit']
      },
    ],
  },

  //Advance Profiling
  {
    route: null,
    label: LN[Lang].advance_profiling,
    permission: [Roles.admin],
    child: [
      {
        route: '/survey/create',
        label: LN[Lang].create_survey,
        permission: [Roles.admin],
      },
      {
        route: '/survey/list',
        label: LN[Lang].survey_list,
        permission: [Roles.admin],
        childShouldActive: ['/survey/:id/details', '/survey/:id/edit', '/survey/:id/questions/create', '/survey/:id/participants/list']
      },
    ],
  },

  //Notice
  // {
  //   route: '/notice',
  //   label: LN[Lang].notice,
  //   permission: [Roles.admin],
  //   child: [],
  // },

  //Contact
  // {
  //   route: '/contact',
  //   label: LN[Lang].contact,
  //   permission: [Roles.admin],
  //   child: [],
  //   childShouldActive: ['/contact/update/:id/:type']
  // },

  //Privacy Agreement
  // {
  //   route: '/privacy',
  //   label: LN[Lang].privacy_agreement,
  //   permission: [Roles.admin],
  //   child: [],
  //   childShouldActive: ['/privacy/privacy_list', '/privacy_accept/user_list']
  // },

  //Manage Users Account
  // {
  //   route: null,
  //   label: LN[Lang].manage_user_account,
  //   permission: [Roles.admin],
  //   child: [
  //     {
  //       route: '/manage_users/account_delete_requests',
  //       label: LN[Lang].account_delete_request,
  //       permission: [Roles.admin],
  //     },
  //   ],
  // },

  //Banners
  // {
  //   route: null,
  //   label: LN[Lang].banners,
  //   permission: [Roles.admin],
  //   child: [
  //     {
  //       route: '/banners/settings',
  //       label: LN[Lang].banners,
  //       permission: [Roles.admin],
  //     },
  //     // {
  //     //   route: '/banners/category/show',
  //     //   label: LN[Lang].category,
  //     //   permission: [Roles.admin],
  //     // },
  //   ],
  // },

  //Settings
  {
    route: null,
    label: LN[Lang].settings,
    permission: [Roles.admin],
    child: [
      {
        route: '/settings/max_item',
        label: LN[Lang].max_items,
        permission: [Roles.admin],
      },
      {
        route: '/settings/payment_key',
        label: LN[Lang].payment_key,
        permission: [Roles.admin],
      },
      {
        route: '/settings/admin_mail',
        label: LN[Lang].admin_mail,
        permission: [Roles.admin],
      },
      {
        route: '/settings/delivery_cost',
        label: LN[Lang].deliver_cost,
        permission: [Roles.admin],
      },
      {
        route: '/settings/referral_points',
        label: LN[Lang].referral_points,
        permission: [Roles.admin],
      },
      {
        route: '/settings/mlm',
        label: LN[Lang].mlm,
        permission: [Roles.admin],
      },
      {
        route: '/settings/shopping_points',
        label: LN[Lang].shopping_points,
        permission: [Roles.admin],
      },
      {
        route: '/settings/shopping_points_to_coupon',
        label: LN[Lang].Points_To_Coupon,
        permission: [Roles.admin],
      },
      {
        route: '/settings/branch_users_list',
        label: LN[Lang].branch_users,
        permission: [Roles.admin],
        childShouldActive: ['/settings/branch_users_create', '/settings/branch_users_edit/:id']
      },
      {
        route: '/settings/create_product_attributes',
        label: LN[Lang].product_attributes,
        permission: [Roles.admin],
      },
      {
        route: '/contact',
        label: LN[Lang].contact,
        permission: [Roles.admin],
        childShouldActive: ['/contact/update/:id/:type']
      },
      {
        route: '/manage_users/account_delete_requests',
        label: LN[Lang].cancellation_requests,
        permission: [Roles.admin],
      },
      {
        route: '/privacy',
        label: LN[Lang].privacy_terms_of_services,
        permission: [Roles.admin],
        childShouldActive: ['/privacy/privacy_list', '/privacy_accept/user_list']
      }
    ],
  },

   //About Us
   {
    route: null,
    label: LN[Lang].About_Us,
    permission: [Roles.admin],
    child: [
      {
        route: '/team',
        label: LN[Lang].Our_Team,
        permission: [Roles.admin],
        childShouldActive: ['/team', '/team/create', '/team/edit/:id']
      },
      {
        route: '/blog',
        label: LN[Lang].Blog,
        permission: [Roles.admin],
        childShouldActive: ['/blog', '/blog/create', '/blog/edit/:id']
      },
      {
        route: '/gallery',
        label: LN[Lang].Gallery,
        permission: [Roles.admin],
        childShouldActive: ['/gallery', '/gallery/create', '/gallery/edit/:id']
      },
    ],
  },

   //Quick Chat
   {
    route: '/quick-chat',
    label: LN[Lang].Quick_Chat,
    permission: [Roles.admin],
    child: [],
  },

  //MLM
  // {
  //   route: '/mlm',
  //   label: LN[Lang].mlm || 'MLM',
  //   permission: [Roles.admin],
  //   child: [],
  // },

  //My Account
  {
    route: null,
    label: LN[Lang].my_account,
    permission: [Roles.admin],
    child: [
      {
        route: '/my_account/change_password',
        label: LN[Lang].change_password,
        permission: [Roles.admin],
      },
      {
        route: '/my_account/security_questions',
        label: LN[Lang].security_questions,
        permission: [Roles.admin],
      },
    ],
  },

  //Help & Various
  {
    route: '/help/help_and_various',
    label: LN[Lang].help_and_various,
    permission: [Roles.admin],
    child: [],
  },

  //APP VIDEO TOUR
  {
    route: '/video_tour',
    label: LN[Lang].app_video_tour,
    permission: [Roles.admin],
    child: [],
  },

]
