class RouteAuthentication {
    login(cb) {
        localStorage.setItem('userIsLoggedIn', '1');
        cb();
    }
    logout(cb) {
        localStorage.removeItem('userIsLoggedIn');
        cb();
    }

    isAuthenticated() {
        let logged = localStorage.getItem('userIsLoggedIn');
        if (logged != null) {
            return true;
        } else {
            return false;
        }
    }
}

export default new RouteAuthentication();
