import { lazy } from 'react';
import Roles from '../../common/Roles';

const VideoTour = lazy(() => import('../../pages/video_tour/VideoTour'));


export const VideoTourRoutes = [
  {
    path: '/video_tour',
    exact: true,
    component: VideoTour,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
