import { lazy } from 'react';
import Roles from '../../common/Roles';



const ProductExcelUplaod = lazy(() => import('../../pages/product_excel/ProductExcelUpload'));
const ExcelProductList = lazy(() => import('../../pages/product_excel/ExcelProductList'));
const ExcelProductDetails = lazy(() => import('../../pages/product_excel/ExcelProductDetails'));
const ExcelProductUpdate = lazy(() => import('../../pages/product_excel/ExcelProductUpdate'));
const ProductImageUpload = lazy(() => import('../../pages/product_excel/ProductImageUpload'));
const ProductImageList = lazy(() => import('../../pages/product_excel/ProductImageList'));


//


export const ProductRoutes = [
  {
    path: '/product/upload',
    exact: true,
    component: ProductExcelUplaod,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/product/list',
    exact: true,
    component: ExcelProductList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/:id/details',
    exact: true,
    component: ExcelProductDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/:id/edit',
    exact: true,
    component: ExcelProductUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/product/image/upload',
    exact: true,
    component: ProductImageUpload,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/product/image/list',
    exact: true,
    component: ProductImageList,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
