import { lazy } from 'react';
import Roles from '../../common/Roles';



const ReviewsCreate = lazy(() => import('../../pages/reviews/ReviewsCreate'));
const ReviewsList = lazy(() => import('../../pages/reviews/ReviewsList'));
const ReviewDetails = lazy(() => import('../../pages/reviews/ReviewDetails'));
const ReviewUpdate = lazy(() => import('../../pages/reviews/ReviewUpdate'));


//


export const ReviewsRoutes = [
  {
    path: '/reviews/create',
    exact: true,
    component: ReviewsCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/reviews/list',
    exact: true,
    component: ReviewsList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/reviews/:id/details',
    exact: true,
    component: ReviewDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/reviews/:id/edit',
    exact: true,
    component: ReviewUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
