import { lazy } from 'react';
import Roles from '../../common/Roles';

const Home = lazy(() => import('../../pages/home/HomePage'));

export const HomeRoutes = [
  {
    path: '/home',
    exact: true,
    component: Home,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    //   resource: PROCUREMENT.REQUISITION.REQUISITION_WRITE,
    },
  },
  
]
