import { lazy } from 'react';
import Roles from '../../common/Roles';


const BannersSetting = lazy(() => import('../../pages/banners/BannersSetting'));
const CategoryShow = lazy(() => import('../../pages/banners/CategoryShow'));

export const BannersRoutes = [
  {
    path: '/banners/settings',
    exact: true,
    component: BannersSetting,
    meta: {
      permissions: [Roles.admin],
    },
  },

  {
    path: '/banners/category/show',
    exact: true,
    component: CategoryShow,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
