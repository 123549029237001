import { lazy } from 'react';
import Roles from '../../common/Roles';

const LastMinuteOfferCreate = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferCreate'));
const LastMinuteOfferList = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferList'));
const LastMinuteOfferDetails = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferDetails'));
const LastMinuteOfferUpdate = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferUpdate'));
const LastMinuteOfferProducts = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferProducts'));
const LastMinuteOfferBookings = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferBookings'));
const LastMinuteOfferProductsDetails = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferProductsDetails'));
const LastMinuteOfferBookingItems = lazy(() => import('../../pages/last_minute_offer/LastMinuteOfferBookingItems'));

export const LastMinuteRoutes = [
  {
    path: '/lastminute/offer/create',
    exact: true,
    component: LastMinuteOfferCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/lastminute/offer/list',
    exact: true,
    component: LastMinuteOfferList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/lastminute/offer/:id/details',
    exact: true,
    component: LastMinuteOfferDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/lastminute/offer/:id/edit',
    exact: true,
    component: LastMinuteOfferUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/lastminute/offer/productlist',
    exact: true,
    component: LastMinuteOfferProducts,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/lastminute/offer/bookinglist',
    exact: true,
    component: LastMinuteOfferBookings,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/lastminute/offer/product/:id/details',
    exact: true,
    component: LastMinuteOfferProductsDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },  
  {
    path: '/lastminute/offer/booking/:id/items',
    exact: true,
    component: LastMinuteOfferBookingItems,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
