export const ES = {
  //WEB__APPLICATION__TEXT_(english)

  //COMMON_TEXT
  bigdata: "BIGDATA",
  welcome: "Bienvenido",
  Next: "El proximo",
  EDIT: "EDIT",
  CREATE: "CREATE",
  RENAME: "Rename",
  Details: "Details",
  Optional: "Optional",
  Start_Date: "Start Date",
  End_Date: "End Date",
  Active: "Active",
  write_here: "Escriba aqui......    ",
  SUBMIT: "ENVIAR",
  Successfully_Done: "Operación realizada con éxito",
  Something_Went_Wrong_Try_Again: "Algo salió mal, intenta de nuevo ...",
  Your_item_has_been_deleted: "Tu artículo ha sido eliminado",
  Search_Option: "Investigación",
  First_Name: "Nombre de pila",
  Last_Name: "Apellido",
  Username: "Nombre de usuario",
  Email: "Correo electrónico",
  Mobile: "Móvil",
  New_Password: "Nueva contraseña",
  Confirm_New_Password: "Confirmar nueva contraseña",
  Select_one: "Seleccione uno",
  Action: "Acción",
  type: "Tipo",
  total: "Total",
  Back: "atrás",
  Update: "Actualizar",
  Birth_Date: "Fecha de nacimiento",
  Gender: "Sexo",
  Phone_Number: "Número de teléfono",
  Postal_Code: "CÓDIGO POSTAL",
  Province: "provincia",
  Search: "Investigación",
  Municipality: "Común",
  Address: "Dirección",
  Total_Family_Member: "Número de miembros de la familia",
  Fidelity_Card_Collection: "Colección de tarjetas de fidelidad:",
  FROM_SALE_POINT: "DESDE EL PUNTO DE VENTA",
  SEND_TO_POSTAL_CODE: "ENVIAR AL CÓDIGO POSTAL",
  SUBMIT_YOUR_FILE: "ENVÍA TU ARCHIVO",
  SELECTED: "SELECCIONADO",
  LAST_FILE_IS_NOT_FULLY_UPLOADED_SOMETHING_WENT_WRONG:
    "EL ÚLTIMO ARCHIVO NO ESTÁ COMPLETAMENTE CARGADO ALGO VA INCORRECTO",
  Not_exist: "No existe",
  Field_is_empty: "El campo esta vacio",
  Are_you_sure: "¿Está seguro?",
  You_wont_be_able_to_revert_this: "¡No podrá restaurar si continúa!",
  Yes_delete_it: "¡Sí, bórralo!",
  Yes_confirm_it: "¡Sí, confirmarlo!",
  Cancel: "Cancelar",
  Operation_Successful: "Misión cumplida",
  Product_Description: "Descripción del Producto",
  Name: "Nombre de pila",
  REMOVE: "PARA ELIMINAR",
  Successfully_Done: "Operación realizada con éxito",
  OOPS: "UPS",
  Created_Successfully: "Creado con éxito",
  print: "Impresión",
  print_preview: "Vista previa de impresión",
  to: "a",
  please_enter_empty_field: "Please, enter the empty field !",
  export: "Exportar",
  export_all: "Exportar todo",
  exporting: "Exportar...",
  Download: "Descarga",
  Download_all: "Descargar todo",
  Previous_Exported_File_Download: "Descarga de archivo exportado anterior",
  LAST_FILE_EXPORT_FAILED: "EXPORTACIÓN DEL ÚLTIMO ARCHIVO, ¡FALLIDO!",
  there_is_no_data: "No hay datos",

  //Header
  help: "Auxilio",

  //LOGIN/FORGOT_PASS_MODULE
  login: "Acceso",
  forgot_password: "Olvidaste tu continúatraseña",
  The_Password_Recovery_Questions: "Preguntas de recuperación de contraseña",
  Question_No_1: "Pregunta n. 1",
  Question_No_2: "Pregunta n. 2",
  Question_No_3: "Pregunta n. 3",
  Change_Password: "cambia tu contraseña",
  Password: "Contraseña",
  Confirm_Password: "Confirmar Contraseña",
  password_must_be_at_least_6_characters_in_length:
    "La contraseña debe contener al menos 6 caracteres",
  Make_sure_all_fields_are_filled_where_password_and_confirm_password_should_be_matched:
    "Asegúrese de que todos los campos estén completos y de que su contraseña y la confirmación de contraseña sean las mismas",
  password_mismatched: "Las contraseñas no coinciden",
  Old_Password: "Contraseña anterior",
  There_is_no_security_questions_you_will_not_be_able_to_recover_your_password_in_future:
    "No hay preguntas de seguridad, no podrá recuperar su contraseña en el futuro",
  PASSWORD_MISMATCHED_TRY_AGAIN: "LA CONTRASEÑA NO COINCIDE, POR FAVOR INTENTE",
  Please_Enter_You_Old_Password: "Ingrese su contraseña anterior",

  Make_sure_all_fields_are_filled:
    "Asegúrese de que todos los campos estén completos",
  Your_Answer: "Tu respuesta",
  Your_Question: "Tu pregunta",
  Your_Security_Questions_For_Future_Password_Reset_Authorizations:
    "Sus preguntas de seguridad para futuros permisos de restablecimiento de contraseña",

  enter_one_time_password: "Por favor ingrese la contraseña de un solo uso",
  to_verify_your_account: "para verificar tu cuenta",
  code_has_been_sent:
    "Se ha enviado un código a su cuenta de correo electrónico registrada",
  your_code_is_wrong: "Introduce el código correcto",

  //SIDE_BAR
  home: "Pagina principal",
  fidelity_card_request: "Solicitud de tarjeta de fidelidad",
  fidelity_card: "Tarjetas de fidelidad",
  fidelity_card_upload: "Cargando tarjetas de fidelidad",
  fidelity_card_list: "Lista de tarjetas de fidelización",
  product: "Productos",
  product_excel_upload: "Carga de productos en Excel",
  product_list: "Lista de productos",
  product_image_upload: "Cargando imágenes de productos",
  product_image_list: "Lista de imágenes de productos",
  product_info: "Productos Destacados",
  product_info_create: "Crear nuevo producto destacado",
  product_info_list: "Lista de productos destacados",
  product_orders: "Pedidos de comercio electrónico",
  last_minute_offer: "Ofertas de última hora",
  offer_create: "Crear nueva oferta",
  offer_list: "Lista de ofertas",
  user_points: "Puntos de usuario",
  points_list: "Lista de puntos",
  points_conversion_list: "Lista de conversión de puntos",
  points_conversion_requests: "Solicitudes de conversión de puntos",
  export_user_points: "Exportar puntos de usuario",
  communication: "Comunicaciones",
  send_message: "Enviar mensaje",
  message_list: "Lista de mensajes",
  institutional_flyers: "Flyers institucionales",
  create_flyer: "Crear folleto",
  flyers_list: "Lista de volantes",
  sales_points: "Historias",
  upload_sales_point_info: "Cargar información de la tienda",
  sales_point_list: "Lista de puntos de venta",
  delivery_slot: "Ranura de entrega",
  sales_points_delivery_slot: "Ranuras de entrega en el punto de venta",
  upload_delivery_slots: "Ranura de entrega de carga",
  special_delivery_dates: "Fechas de entrega especiales",
  ticket_management: "Gestión de tickets",
  news: "Noticias",
  create_news: "Crear noticias",
  news_list: "Lista de noticias",
  events: "Eventos",
  create_event: "Crear evento",
  event_list: "Lista de eventos",
  reviews: "Reseñas",
  create_reviews: "Crear reseñas",
  reviews_list: "Lista de reseñas",
  advance_profiling: "Perfilado avanzado",
  create_survey: "Crear encuesta",
  survey_list: "Lista de encuestas",
  notice: "darse cuenta",
  contact: "Contacto",
  privacy_agreement: "Acuerdo de privacidad",
  privacy_terms_of_services: "Privacidad y términos de servicios",
  cancellation_requests: "Solicitudes de cancelación",
  manage_user_account: "Administrar cuenta de usuarios",
  account_delete_request: "Solicitudes de eliminación de cuenta",
  settings: "Ajustes",
  max_items: "Artículo Máximo",
  branch_users: "Usuarios de la sucursal",
  payment_key: "Clave de pago",
  admin_mail: "Correo electrónico de notificación del administrador",
  my_account: "mi cuenta",
  change_password: "Cambiar la contraseña",
  security_questions: "Preguntas de seguridad",
  image_management: "Gestión de imágenes",

  //FIDELITY_CARD_REQUEST_MODULE
  Fidelity_Card_Requests_List:
    "Lista de solicitudes de tarjetas de fidelización",
  Temporary_Username: "Nombre de usuario temporal",
  Full_Name: "Nombre y apellido",
  Fidelity_Card_Request_Details:
    "Detalles de la solicitud de tarjeta de fidelidad",
  SALE_POINT_DETAILS: "DETALLES DEL PUNTO DE VENTA",
  Sale_Point_Name: "Nombre de la tienda",
  Sale_Point_Code: "Código de punto de venta",
  Sale_Point_Address: "Dirección de la tienda",
  There_is_no_requests: "No hay solicitudes",
  new_request: "Solicitudes no vistas",

  //FIDELITY_CARD_MODULE
  FIDELITY_CARD_INFO_UPLOAD: "CARGAR INFORMACIÓN TARJETAS DE FIDELIDAD",
  FIDELITY_CARD: "TARJETAS DE FIDELIDAD",
  There_is_no_fidelity_card_info:
    "No hay información de la tarjeta de fidelización.",
  IDCard: "IDFidelityCard",
  TipoProfilaz: "Tipo de perfil",
  Comune_di_Residenza: "Ciudad de residencia",
  Fidelity_Card_Details: "Detalles de la tarjeta de fidelidad",
  EXCEL_UPLOAD_INFO: "EXCELAR CARGAR información",
  USER_GIVEN_INFO: "INFORMACIÓN PROPORCIONADA POR EL USUARIO",
  File_Is_Uploading: "El archivo se está cargando",
  IDPuntoVendita: "IDPuntoVendita",
  Regione_di_Residenza: "Región de residencia",
  Stato_Fam: "Estado Fam",
  Num_Figli: "Num Sons",
  Altri_Comp: "Otros Comp",
  Spesa_Altri_A: "Gastos Otros (A)",
  Se_SI_quanta_spesa: "Si la respuesta es 'SÍ', ¿cuánto gasta +/-? (B)",
  Verification_Status: "Estado de verificación",
  Verification: "Verificación",
  Verified: "Verificada",
  Not_Verified: "No verificada",
  Verify: "Verificar",
  Remove: "Eliminar",
  Verify_Successful: "Verificar exitosa",
  Verify_removed_successfully: "Verificar eliminado con éxito",
  SEND_MAIL_TO_ALL_UNVERIFIED_USERS:
    "ENVIAR CORREO A TODOS LOS USUARIOS NO VERIFICADOS",

  //PRODUCT_MODULE
  Product: "Producto",
  PRODUCT_EXCEL_UPLOAD: "EXCELENTE CARGA DE PRODUCTOS",
  Product_Details: "Detalles de producto",
  Product_Image_Upload: "Cargando imágenes de productos",
  All_Images: "Todas las imágenes",
  Upload_Single_Image: "Subir una sola imagen",
  Delete_All_Image: "Eliminar todas las imágenes",
  View_Product: "Ver el producto",
  TOTAL_IMAGE: "IMÁGENES TOTALES",
  Edit_Name: "Cambiar nombre",
  Delete: "Borrar",
  Write_Full_Product_name: "Escribe el nombre completo del producto.",
  There_is_no_products: "No hay productos",
  This_name_already_exists: "Este nombre ya existe",
  Product_Name_Required: "Nombre del producto requerido",
  Image_Doest_Not_Exist: "La imagen no existe",
  image_name_is_required: "El nombre de la imagen es obligatorio.",
  SUBMIT_YOUR_ZIP_FILE: "ENVÍA TU ARCHIVO ZIP",
  Product_Image: "Imagen del producto",

  Pvp: "JcJ",
  Iva_Percent: "Porcentaje de IVA",
  Data_Out: "Salida de datos",
  Data_In: "Fecha en",
  Promo_Act: "Ley de promoción",
  Przlist: "Przlist",
  Exclusion_Man: "Hombre de exclusión",
  Um: "Um",
  Codice_Ean: "Código EAN",
  Raggr03_Originale: "Raggr03 Originale",
  Raggr02_Originale: "Raggr02 Originale",
  Raggr01_Originale: "Raggr01 Originale",
  Totale_Smk: "Smk total",
  Codice: "Código",

  dec: "Dec",
  in_stock: "En stock",
  max_value: "Valor Máximo",
  min_value: "Valor Mínimo",

  active_product: "PRODUCTO ACTIVO",
  disable_product: "PRODUCTO DISCAPACITADO",

  product_export_failed: "La exportación del producto falló",
  all_sales_points: "Todos los puntos de venta",

  //PRODUCT_INFO_CATALOGUE_MODULE
  Product_Name: "Nombre del producto",
  Product_Code: "Código de producto",
  upload_at_least_one_item: "Subir al menos un elemento",
  Upload_Image_1: "Subir imagen 1",
  Upload_Image_2: "Subir imagen 2",
  Upload_Image_3: "Subir imagen 3",
  Upload_Image_4: "Subir imagen 4",
  Upload_Image_5: "Subir imagen 5",
  Upload_Video: "Subir video URL (Embedded)",
  Upload_Audio: "Subir audio URL (Embedded)",
  Code: "Código",
  Product_Code: "Código de producto",
  Product_Details: "Detalles de producto",
  Product_Info_updated_successfully:
    "Información del producto actualizada correctamente",

  //ECOMMERCE_MODULE
  Order_List: "Lista de Order_No",
  Order_Moving: "Order Moving",
  Move: "Move",
  Order_No: "Número de orden",
  Order_From: "Ordenar desde",
  Order_Date: "Fecha de la orden",
  Delivery_Date: "Fecha de entrega",
  sub_total: "Subtotal",
  Status: "Expresar",
  Action: "Acción",
  CLIENT: "CLIENTE",
  Sale_Point: "Punto de venta",
  Order_No: "Número de orden",
  Order_Datetime: "Fecha y hora del pedido",
  Order_Note: "Nota de pedido",
  Order_Payment: "Pago del pedido",
  CARD_PAYMENT: "PAGO CON TARJETA",
  CASH_ON_DELIVERY: "EFECTIVO EN LA ENTREGA",
  PAYPAL_PAYMENT: "FORMA DE PAGO: PAYPAL",
  ID: "IDENTIFICACIÓN",
  Name: "Nombre de pila",
  Delivery_Type: "Tipo de entrega",
  Delivery_Date: "Fecha de entrega",
  Delivery_Slot: "Tiempos de entrega",
  Change: "Modificar",
  Change_Info: "Editar informacion",
  Products_Chat: "Productos / Chat",
  CHAT_WITH_USER: "CHATEA CON EL USUARIO",
  ADD_NEW_PRODUCT: "AÑADIR NUEVO PRODUCTOS",
  Quantity: "Cantidad",
  Note: "Nota",
  Send_Message: "Enviar mensaje",
  There_is_no_new_chat_notification: "No hay nuevas notificaciones de chat.",
  Unseen_Order_List_Messages:
    "No se muestran los mensajes de la lista de pedidos",
  Fails_To_Send_Message: "No se pudo enviar el mensaje",
  CONFIRM_ORDER: "CONFIRMAR PEDIDO",
  There_is_no_orders: "No hay ordenes",
  click_to_select_a_date: "haga clic para seleccionar una fecha",
  Yes_Change_it: "¡Sí, cámbialo!",
  You_can_change_it_later: "Puedes cambiarlo más tarde",
  Product_Codice_Ean_doesnt_exist_in_store:
    "El producto con este código Ean no existe en la tienda",
  PLEASE_SELECT_DELIVERY_SLOT_TIME: "POR FAVOR SELECCIONE EL TIEMPO DE ENTREGA",
  You_have: "tú tienes ",
  hand_written_products_first_remove_this_products:
    " productos escritos a mano, primero retire este producto",
  Order_Details: "Detalles del pedido",
  ORDER: "PEDIDO",
  Choose: "Elegir",
  Available_slot: "Ranura disponible",
  Total_Price: "Precio total",
  EDIT: "CAMBIO",
  Product_Quantity: "Cantidad de producto",
  chat_message: "mensaje de chat",

  Order_Total_Amount: "Total del pedido",
  Shipping_Cost: "Gastos de envío",
  Current_extra_cost: "Current extra cost",
  Order_Total_Final_Amount: "Gran total de la orden",
  SUBMIT_COUPONS: "APLICAR CUPONES",
  Subtotal: "Total parcial",
  Shipping: "Entrega",
  Grand_Total: "Totales finales",
  Invoice: "Recibo",
  Order: "Orden",
  sorry_can_not_move_order: "Sorry, you can't move this order",
  these_products_out_of_stock: "These products out of stock",
  You_can_move_the_order_only_for_buying_request:
    "You can move the order only for buying request.",
  You_can_move_the_order: "You can move the order",
  Successfully_match_all_items: "Successfully match all items",
  EXTRA_COST: "EXTRA COST",
  extra_cost_validation_msg:
    "Extra cost must be less then total order amount, when Subtract",

  Product_Coupons: "Cupones de productos",
  Code: "Código",
  Percentage: "Porcentaje",
  Discount_Euro: "euros de descuento",
  Current_Amount: "Cantidad actual",
  Partial_Use: "uso parcial",

  Send_Push_Notification: "Enviar notificación de inserción",

  //LAST_MINUTE_OFFER_MODULE
  For_Which_Sales_Point: "Para que punto de venta",
  PDF: "PDF",
  Organization: "Organización",
  Item_Type: "Tipo de artículo",
  Offer_List: "Offer List",
  Booking_List: "Booking List",
  Product_List: "Product List",
  Last_Minute_Offer_Details: "Detalles de la oferta de última hora",
  There_is_no_offers: "No hay ofertas",
  Last_Minute_Offer_Created_Succesfully:
    "Oferta de último minuto creada correctamente",
  Last_Minute_Offer_Of_This_Sale_Point_Already_Exists:
    "¡¡La oferta de última hora de este punto de venta ya existe !!",

  Sales_Points_List_Loading_Problem:
    "Problemas para cargar la lista de la tienda",
  UPLOAD_IMAGE_PDF: "SUBIR IMAGEN / PDF",
  Last_Minute_Offer_List: "Lista de ofertas de última hora",
  Last_Minute_Offer_Update: "Actualización de la oferta de última hora",

  lastMinuteOfferBookingItems: "Last Minute Offer Booking Items",
  requestId: "ID de Solicitud: ",
  back: "Atrás",
  productName: "Nombre del Producto",
  quantity: "Cantidad",
  salesPoint: "Punto de Venta",
  date: "Fecha",
  noOffers: "No hay ofertas disponibles.",
  totalItem: "Totale Articolo",
  total: "Totale",
  details: "Dettagli",
  lastMinuteOfferProductDetails: "Detalles del Producto de Oferta de Último Minuto",

  //COMMUNICATION_MODULE
  Message_Send: "Enviar mensaje",
  Message: "Mensaje",
  Message_List: "Lista de mensajes",
  Send_By: "publicado por",
  YOUR_MESSAGE: "TU MENSAJE",
  There_is_no_messages: "No hay mensajes",
  Message_Update_Successfully: "Mensaje actualizado con éxito",

  Message_Update: "Actualización de mensaje",

  //INSTITUTIONAL_FLYERS
  Flyer_Starting_Date: "Fecha de inicio del folleto",
  Flyer_Ending_Date: "Fecha de finalización del folleto",
  upload_flyer_image: "sube la imagen del volante",
  Institutional_Flyer_List: "Lista de folletos institucionales",
  There_is_no_flyer_available: "No hay folleto disponible.",
  Flyer_Has_Been_Created_Successfully: "El folleto se creó con éxito",
  Please_upload_the_file: "Sube el archivo",
  Flyer_Has_Been_Updated_Successfully:
    "El folleto se ha actualizado correctamente.",

  Image: "Imagen",
  Flyer_Update: "Actualización del folleto",

  //SALES_POINT_MODULE
  Branch_Info_Upload: "Cargar información de la sucursal",
  Branch_Service_Upload: "Servicio de sucursal de carga",
  Sales_Points_List: "Lista de puntos de venta",
  Sales_Points: "Puntos de venta",
  Zone: "Área",
  Comune: "Común",
  BRANCH_DETAILS: "DETALLES DE LA SUCURSAL",
  Telephone: "Teléfono",
  Region: "Región",
  BRANCH_DELIVERY_SLOT: "RANURA DE ENTREGA DE LA SUCURSAL",
  HOME_DELIVERY_SLOT: "RANURA DE ENTREGA A DOMICILIO",
  Order_Collect_Time: "Hora de recogida de pedidos",
  Monday: "lunes",
  Tuesday: "martes",
  Wednesday: "miércoles",
  Thrusday: "jueves",
  Friday: "viernes",
  Saturday: "sábado",
  Sunday: "domingo",
  Order_Close_Time: "Hora de cierre de la orden",
  Collect_from_store_slot: "Recoger de la ranura de la tienda",
  BRANCH_SERVICES: "SERVICIOS DE SUCURSAL",
  YES: "SÍ",
  NO: "NO",
  There_is_no_branches: "No hay ramas",
  Service_status_is_changed: "El estado del servicio ha cambiado",
  Make_sure_to_fill_all_the__red_marking_fields:
    "Asegúrese de completar todos los campos marcados en rojo (*)",
  Sale_point_updated_sucessfully: "Punto de venta actualizado correctamente",
  Sales_Points_Details: "Detalles del punto de venta",
  Respo1: "Respo1",
  Respo2: "Respo2",
  Respo3: "Respo3",
  Make_sure_to_fill_all_the_fields_with_red_sign:
    "Asegúrese de completar todos los campos con la marca roja (*)",
  Sales_Points_Update: "Actualización del punto de venta",
  select_province_first: "seleccione la provincia primero",
  select_region_first: "seleccione la región primero",

  //DELIVERY_SLOT_MODULE
  Upload_sales_points_delivery_time_table:
    "Sube los tiempos de entrega de los puntos de venta",
  Add_Special_Date_and_Delivery_Slots_for_Stores:
    "Agregue fechas especiales y espacios de entrega para las tiendas",
  Select_Store_by_shop_Code: "Seleccione Comprar por código de tienda",
  None_Selected: "Ninguna seleccionada",
  select_all: "seleccionar todo",
  Special_Date: "Fecha especial",
  Special_Dates: "Fechas especiales",
  click_to_select_date: "Haga clic para seleccionar la fecha",
  ADD: "INSERTAR",
  SUBTRACT: "SUBTRACT",
  Home_Delivery: "Entrega a domicilio",
  Delivery_From_Store: "Entrega de la tienda",
  Home_Delivery_Slot: "Tragamonedas de entrega a domicilio",
  Store_Delivery_Slot: "Espacio de entrega en tienda",
  Home_Delivery_Same_Day_Delivery_Order_Closing_Time:
    "Entrega a domicilio Entrega el mismo día Hora de cierre del pedidos",
  Store_Delivery_Same_Day_Delivery_Order_Closing_Time:
    " Entrega en tienda Entrega el mismo día Hora de cierre del pedido",

  Choose_delivery_availability_for_home_delivery_and_delivery_from_store:
    "Elija la disponibilidad de entrega para entrega a domicilio y entrega desde tienda",
  Please_add_one_or_multiple_dates: "Agregue una o más fechas",
  Please_select_one_or_multiple_store_from_your_store_list:
    "Seleccione una o más tiendas de la lista de tiendas",
  Selected_Dates: "Fechas seleccionadas",

  //TICKET_MANAGEMENT_MODULE
  Ticket_Number: "Numero de ticket",
  Phone_Number: "Número de teléfono",
  Category: "Categoría",
  OPEN: "ABIERTO",
  CLOSE: "CERRAR",
  Ticket_Created: "Tarjeta creada",
  Contact_No: "Contacto n.",
  Ticket_Messages: "Mensajes de tickets",
  ADMIN: "Administrador",
  USER: "USUARIO",
  Reply: "Respuesta",
  There_is_no_tickets: "No hay entradas",
  Ticket_doesnt_exist: "El ticket no existe",
  Tickets_List: "Lista de tickets",
  Ticket_Number_is_required: "El número de ticket es obligatorio",
  Tickets_Details: "Detalles de las tickets",
  TICKET: "BILLETE",
  Tickets: "Tickets",
  Tickets_Categories: "Tickets Categories",
  Category_ID: "Category ID",
  Ticket_Category_Update: "Ticket Category Update",
  Ticket_Category_Create: "Ticket Category Create",

  //NEWS_MODULE
  News_Create: "Noticias Crear",
  News_Title: "Título de la noticias",
  News_Description: "Descripción de la noticia",
  Upload_News_Image: "Subir imagen de noticias",
  News_List: "Lista de noticias",
  News_Image: "Imagen de noticias",
  News_Details: "Detalles de la noticia",
  News_Has_Been_Created_Successfully: "La noticia se creó con éxito",
  There_is_no_news: "No hay novedades",
  News_Has_Been_Updated_Successfully: "La noticia se ha actualizado con éxito",
  new_description_required: "se requiere descripción de la noticia",
  News_Update: "Actualización de las noticias",

  // USER_POINT MODULE
  User_Points_List: "Elenco punti utente",
  Total_Earning_Points: "Puntos totales",
  Current_Points: "Puntos Actuales",
  Pending_Points: "Puntos pendientes",
  Approved_Points: "Puntos Aprobados",
  Rejected_Points: "Puntos rechazados",
  User_Points_Details: "Detalles de puntos de usuario",
  target_points: "Target Points",
  referral_points_list: "Referral Points List",
  referral_points_details: "Referral Points Details",
  Invitation_Points: "Invitation Points",
  Use_Referral_Code: "Use Referral Code",
  Accepting_Points: "Accepting Points",
  Referred_Users: "Referred Users",
  Start_date_must_be_less_then_end_date:
    "Start date must be less then end date",

  Points_Conversion_List: "Lista de conversión de puntos",
  Points_Conversion_Requests: "Solicitudes de conversión de puntos",
  Points_To_Coupon: "Puntos a cupón",
  Terms_and_conditions: "Terms and conditions",
  Generation: "Order Refer Generation",
  Referral_Points: "Referral App Download",
  Generation_label: "Generation label",
  Earning_Points: "Earning Points",
  generation_points_details: "Generation Points Details",

  // all status
  Earned_From_Order: "Obtenido del pedido",
  Coupons_Request_Pending: "Solicitud de cupones pendiente",
  Coupons_Request_Approved: "Solicitud de cupones aprobada",
  Coupons_Request_Rejected: "Solicitud de cupones rechazada",
  Approved: "Aprobada",
  Rejected: "Rechazada",
  Pending: "Pendiente",
  None: "Ninguna",

  // button label
  Approve: "Aprobar",
  Reject: "Rechazar",
  Approve_All: "Aprobar todo",
  Reject_All: "Rechazar todo",

  // msg
  Approved_All_Request: "Todas las solicitudes aprobadas",
  Rejected_All_Request: "Todas las solicitudes rechazadas",
  Approved_Request: "Solicitud aprobada",
  Rejected_Request: "Solicitud rechazada",

  // placeholder
  Select_Status: "Seleccionar estado",
  Select_Start_Date: "Seleccionar fecha de inicio",
  Select_End_Date: "Seleccionar fecha de finalización",

  // label
  Points: "Puntos",
  Date: "Fecha",
  Requested_Points: "Puntos solicitados",
  Total_Request: "Solicitud total",
  User_ID: "ID de usuario",

  //EVENT_MODULE
  Event_Title: "Título del evento",
  Event_Description: "Descripción del evento",
  Event_Starting_Date: "Fecha de inicio del evento",
  Event_Ending_Date: "Fecha de finalización del evento",
  Event_Location: "Lugar del evento",
  Upload_Event_Image: "Sube la imagen del evento",
  Event_List: "Lista de eventos",
  Event_Image: "Imagen del evento",
  Event_Start: "Inicio del evento",
  Event_End: "Fin del evento",
  Upcoming_Event: "Próximo evento",
  Ongoing_Event: "Evento en curso",
  Event_Details: "Detalles del evento",
  There_is_no_event_of_your_seleted_type:
    "No hay eventos del tipo seleccionado",
  ALL: "TODOS",
  ONGOING: "EN PROGRESO",
  UPCOMING: "PRÓXIMAMENTE",
  Event_Create: "Crear evento",
  Event_Update: "Actualización de eventos",
  Event_Type: "Event Type",
  Free: "Free",
  Paid: "Paid",
  Event_Ticket_Price_Amount: "Event Ticket Price Amount",
  Payment_Method: "Payment Method",
  Cash: "Cash",
  Online_Payment: "Online Payment",
  Both: "Both",
  Please_select_event_type: "Please select event type",
  Please_select_payment_method: "Please select payment method",

  //REVIEWS_MODULE
  Review_Create: "Revisar Create_Reviews",
  REVIEW_SET_NAME: "REVISAR EL NOMBRE DEL CONJUNTO",
  Your_Questions: "Tus preguntas",
  write_your_question_here: "Escribe tu pregunta aquí",
  Reviews_List: "Lista de reseñas",
  Review_Set_Name: "Revisa el nombre del conjunto",
  Review_Details: "Revisa los Detalles",
  Reviews_Update: "Actualización de reseñas",
  If_delete_this_review_set_then_your_questions_with_rating_will_also_be_deleted_and_wont_be_able_to_revert_this:
    "Si elimina este conjunto de revisiones, las preguntas calificadas también se eliminarán y no se podrán restaurar. ",
  At_a_time_you_can_active_deactive_one_review_set_and_other_review_sets_will_be_deactivated:
    "A la vez, puede activar / desactivar un conjunto de revisión y los otros conjuntos de revisión se desactivarán",
  users_rate_this_question: "Los usuarios califican esta preguntas",
  not_yet: "Todavía no",
  Review_Questions_Has_Been_Created_Successfully:
    "Las preguntas de revisión se crearon correctamente.",
  Review_Questions_Has_Been_Updated_Successfully:
    "Las preguntas de revisión se han actualizado correctamente.",

  //ADVANCE_PROFILING_MODULE
  SURVEY: "ENCUESTA",
  SURVEY_SET_NAME: "CONJUNTO DE NOMBRE DE ENCUESTA",
  Survey_Set_List: "Lista de conjuntos de encuestas",
  Set_Name: "Escoger un nombre",
  Create_Date: "Fecha de Creación",
  Last_Edited_Date: "Fecha de la última modificación",
  TOTAL_PARTICIAPTION: "PARTICIPACIÓN TOTALES",
  QUESTIONS: "PREGUNTAS",
  SURVEY_QUESTIONS: "PREGUNTAS DE LA ENCUESTA",
  SURVEY_QUESTIONS_TYPE: "TIPO DE PREGUNTAS DE LA ENCUESTA",
  Single: "Único",
  Multiple: "Múltiple",
  survey_options: "Opciones de encuesta",
  VIEW_ALL_QUESTIONS: "VER TODAS LAS PREGUNTAS",
  UPDATE_SET_NAME: "ACTUALIZAR EL NOMBRE DEL SET",
  UPDATE_YOUR_QUESTIONS: "ACTUALIZA TUS PREGUNTAS",
  DELETE_QUESTION_NO: "BORRAR PREGUNTA N.",
  Update_Question: "Pregunta de actualización",
  maximum_4_options: "Máximo 4 opciones",
  All_survey_questions_and_informations_will_be_removed_and_you_wont_be_able_to_revert_this:
    "¡Se eliminarán todas las preguntas e información de la encuesta y no podrá cancelarlas!",
  At_a_time_you_can_active_deactive_one_survey_set_and_other_survey_sets_will_be_deactivated:
    "En un momento puede activar / desactivar un conjunto de encuestas y otros conjuntos de encuestas se desactivarán",
  There_is_no_survey_question_set:
    "No se han establecido preguntas de la encuesta",
  SET_NAME_EMPTY: "Nombre del SET vacío",

  users: "usuarios",
  All_questions_and_options_will_be_removed_and_you_wont_be_able_to_revert_this:
    "Se eliminarán todas las preguntas y opciones y no podrá restaurarlas",
  Question_No: "Pregunta n",
  Active_Inactive: "Active/Inactive",
  Inactive: "Inactive",
  Active_Survey: "Active Survey",
  No_survey_is_active: "No survey is active",
  Answer_Type: "Answer Type",
  Create_Question: "Create Question",
  View_Questions: "View Questions",
  TOTAL_QUESTION: "TOTAL QUESTION",
  VIEW_ALL_PARTICIPANTS: "View all participants",
  Create_New_Question: "Create New Question",
  Answers: "Answers",
  Reward_Points: "Reward Points",
  Target_Amount: "Target Amount",
  All_Participants: "All Participants",
  back_to_the_survey_list: "Back to the survey list",

  //NOTICE_MODULE
  Notice: "darse cuenta",
  Last_update: "Última actualización",
  published_by: "Publicado POR",
  delete: "Borrar",
  update_notice: "Aviso de actualización",
  Notice_Sent_Successfully: "Aviso enviado con éxito",

  //CONTACT_MODULE
  Contact_Details: "Detalles de contacto",
  Head_Office_Contact: "Contacto de la sede",
  Customer_Care_Contact: "Contacto para soporte al CLIENTE",
  Complaints_Center_Contact: "Comuníquese con el Centro de quejas",
  Information_Center_Contact: "Centro de información de contacto",
  ADD_CONTACT: "AGREGAR CONTACTO",
  Contact_Address: "Dirección de contacto",
  Fax: "Fax",
  Facebook_URL: "Facebook URL",
  Instagram_URL: "URL de Instagram",
  Twitter_URL: "URL de Twitter",
  linkedin_url: "Url linkedin",

  Create_New_Contact_for: "Crear nuevo contacto para",

  //PRIVACY_AGREEMENT_MODULE
  Privacy_Agreement: "Acuerdo de privacidad",
  Privacy_Text: "Texto de privacidad",
  Update_Privacy: "Actualizar privacidad",
  Please_Write_Your_Privacy: "Escribe tu privacidad",
  user_list: "Lista de usuarios aceptados",
  Privacy_List: "Lista de privacidad",
  active_privacy: "Política de privacidad activa",
  deactivated_privacy: "Política de privacidad desactivada",

  //MANAGE_USER_ACCOUNT_MODULE
  Users_Account_Delete_Requests:
    "Solicitudes de eliminación de cuentas de usuarios",

  //SETTINGS_MODULE
  SETTINGS_MODULE: "MÓDULO DE AJUSTES",
  Max_Item_For_Shopping_List: "Artículo máximo por lista de compras",
  Max_Item_For_Favourite_List: "Elemento máximo por lista de favoritos   ",
  Max_Item_For_Essential_List: "Elemento máximo para lista esencial",

  Settings: "Ajustes",

  paypal_client_id: "Paypal Identificación del cliente",
  paypal_secret_key: "Paypal Llave secreta",
  stripe_private_key: "Stripe Clave secreta",
  stripe_publishable_key: "Stripe Clave publicable",
  change_paypal_key: "Cambiar la clave de pago de Paypal",
  change_stripe_key: "Cambiar la clave de pago de Stripe",

  Coupon_Conversion_Rate: "Tasa de conversión de cupón",
  Max_Min_Request: "Solicitud Máxima / Mínima",
  Expiry_Date: "Días de caducidad",

  Maximum_Point: "Máxima Punto",
  Maximum_Euro: "Máxima Euro",
  Minimum_Point: "Mínima Punto",
  Minimum_Euro: "Mínima Euro",
  No_Max_Limit: "Sin límite máximo",
  No_Min_Limit: "Sin límite mínimo",
  No_Days_Limit: "Sin límite de días",

  Point: "Punto",
  Euro: "Euro",
  Days: "Días",

  create_new_user: "Crear nueva usuaria",
  Create_New_Branch_User: "Crear nueva usuaria de sucursal",
  Update_Branch_User: "Actualizar usuario de sucursal",
  Change_User_Password: "Cambiar contraseña de usuario",
  Password_changed_successfully: "Contraseña cambiada con éxito",
  Password_dose_not_match: "La dosis de contraseña no coincide",
  User_update_successfully: "Actualización de usuario exitosa",
  Username_already_exist:
    "El nombre de usuario ya existe. Intente con otro nombre de usuario",
  branch_code: "Código de sucursal",

  product_attributes: "Atributos del producto",
  attributes: "atributos",

  referral_points: "Referral Points",
  Reward_points_for_sending_invitation: "Reward points for sending invitation",
  Reward_points_for_using_referral_code: "Reward points for using referral code",
  
  //ABOUT_MODULE
  About_Us: "Sobre Nosotros",
  Our_Team: "Nuestro Equipo",
  List: "Lista",
  Designation: "Designación",
  View_Position: "Ver Posición",
  No_Team_Members: "No hay Miembros del Equipo",
  Create_Team_Member: "Crear Miembro del Equipo",
  Edit_Team_Member: "Editar Miembro del Equipo",
  Upload_Image: "Subir Imagen",

  Blog: "Blog",
  No_Blog_Found: "No se Encontró Blog",
  Edit_Blog: "Editar Blog",
  Upload_New_Blog: "Subir Nuevo Blog",
  Upload_Image_Video: "Subir Imagen/Video",
  OR: "O",
  Embed_Video_Link: "Insertar Enlace de Video",
  Paste_your_video_link_here: "Pega tu enlace de video aquí:",
  Gallery: "Galería",
  No_Gallery_Item_Found: "No se Encontró Elemento en la Galería",
  Edit_Item: "Editar Elemento",
  Upload_New_Item: "Subir Nuevo Elemento",
  Title: "Título",

  //QUICK CHAT
  Quick_Chat: "Chat Rápido",
  Chat: "Chat",
  Send: "Enviar",
  Customer_List: "Lista de Clientes",
  New: "Nuevo",
  min_ago: "min atrás",
  hrs_ago: "hrs atrás",
  days_ago: "días atrás",
  months_ago: "meses atrás",
  years_ago: "años atrás",

  Delivery_Address: "Dirección de Entrega",
  Address_1: "Dirección 1",
  Address_2: "Dirección 2",
  No_Delivery_Address_Found: "No se Encontró Dirección de Entrega",

  Total_Items: "Total de Artículos",

  //HELP & VARIOUS
  help_and_various: "Ayuda y Varios",
  module_name: "Nombre del Módulo",
  tutorial: "Tutoriales",

  //APP VIDEO TOUR
  app_video_tour: "Recorridos virtuales",
  iframe_link: "enlace de marco flotante",

  //Coupons
  coupons: "Cupones",
  coupon_upload: "Subir cupones",
  coupon_list: "Lista de cupones",
  COUPONS_UPLOAD: "SUBIR CUPONES",
  coupon_search: "Búsqueda de cupones",
  coupon_id_fidelity: "ID Fidelilty",
  coupon_prodotto: "Prodotto",
  coupons_reparto: "Rreparto ",
  coupon_sottoreparto: "Sub Departamento",
  coupon_Codice_EAN: "Codice EAN",
  coupon_Disc_percentage: "Disc_%",
  coupon_Disc_euro: "Disc_€",
  coupons_expiration: "Vencimiento",
  coupons_used: "Usado",
  There_are_no_coupons: "no hay cupones",
  reset_all_coupons: "Restablecer todos los cupones",
  all_coupons_reset_alert:
    "Esta acción no se puede deshacer. ¿Está seguro de que desea restablecer todos los cupones ?",
  export_coupons_before_reset_alert:
    "¿Desea exportar la lista de cupones actual antes de restablecer todos los cupones?",
  yes_reset_all: "Sí, restablecer todo",
  reset: "restablecer todo",
  Select_Department: "Seleccionar Departamento",
  Select_Sub_Department: "Seleccionar Sub Departamento",
  Select_Expiration_Date: "Seleccionar fecha de caducidad",
  barcode: "Barcode",

  //Delivery Cost
  deliver_cost: "Gastos de envío",
  free: "Gratis",
  delivery_cost_free_for_order_amount:
    "Costo di consegna gratuito per l'importo dell'ordine",
  fixed: "Precio fijo",
  percentage: "Porcentaje",
  select_one_delivery_cost_value: "No seleccionó su costo de envío !",
  shipping_cost_validation_msg:
    "Please select one or both options from FIXED SHIPPING COST or PERCENTAGE SHIPPING COST. It will be applicable when the order price will be less than the minimum order price amount.",
  Common_Shipping_Cost: "Common Shipping Cost",
  Shipping_cost_free_for_minimum_order_amount:
    "Shipping cost free for minimum order amount",
  Upload: "Subir",
  Uploading: "Uploading",
  file_upload_successful: "File Upload Successful",
  update_successful: "Update Successful",

  //Shopping Points
  shopping_points: "Monedas electrónicas para compras",
  shopping_points_amount: "eCoins por cantidad",
  shopping_points_total: "Monedas electrónicas",

  //HOME_MODULE
  TOTAL_APP_USERS: "USUARIOS TOTALES DE LA APLICACIÓN",
  ANDROID_IOS: "ANDROID e IOS",
  FIDELITY_CARD_REQUEST: "SOLICITUD TARJETAS DE FIDELIDAD",
  today: "hoy dia",
  ORDER_RECEIVED: "ORDENES RECIBIDAS",
  last_7_days: "Los últimos 7 días",
  FIND_PRODUCT_INFO: "ENCONTRAR INFORMACIÓN DEL PRODUCTO",
  FIND_ORDER_INFO: "ENCONTRAR INFORMACIÓN PARA PEDIDOS",
  CUSTOMER_ANALYSIS: "Análisis de clientes",
  PRODUCT_SALES: "VENTA DE PRODUCTOS",
  Sales_for: "Ventas para",
  TOP_5_PRODUCTS: "LOS 5 MEJORES PRODUCTOS",
  based_on_selling: "basado en la venta",
  APP_DOWNLOADED: "APLICACIÓN DESCARGADA ",
  new_user_for: "nuevo usuario para",
  family_memeber: "MIEMBRO DE LA FAMILIA",
  Jan: "En",
  Feb: "Feb",
  Mar: "Marzo",
  Apr: "Abr",
  May: "Mayo",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sept",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",

  TOTAL_QUESTIONS_PER_REVIEW_SET: "TOTAL DE PREGUNTAS POR GRUPO DE REVISIÓN",
  TOTAL_QUESTIONS_PER_ADVANCE_PROFILING_SET:
    "APLICACIONES TOTALES POR GRUPO DE PERFILACIÓN TEMPRANA",
  REPARTO_ORIGINALE: "DEPARTAMENTO ORIGINAL",
  PROMO_ACT: "PROMO ACTUAL",
  SOTTOREPARTO_ORIGINALE: "SUBDEPARTAMENTO ORIGINAL",
  TOTAL_ORDERS: "PEDIDOS TOTALES",
  TOTAL_VALUES_OF_ORDERS: "CANTIDAD TOTAL DE PEDIDOS",
  CASH_ON_DELIVERY: "EFECTIVO EN LA ENTREGA",
  CARD_PAYMENT: "PAGO CON TARJETA",
  PAYPAL_PAYMENT: "FORMA DE PAGO: PAYPAL",
  HOME_DELIVERY: "ENTREGA A DOMICILIO",
  COLLECT_FROM_STORE: "RETIRO DE LA TIENDA",
  TOTAL_ORDER_ON_STATUS: "TOTAL DEL PEDIDO POR TIPO DE ESTADO",
  TOTAL_ORDER_DELIVERED_PER_MONTH: "PEDIDOS TOTALES ENTREGADOS POR MES",
  TOTAL_ORDER_DELIVERED_PER_DAY: "PEDIDOS TOTALES ENTREGADOS POR DÍA",
  TOTAL_VALUE_OF_ORDER_DELIVERED_PER_MONTH:
    "VALOR TOTAL (€) DE LOS PEDIDOS ENTREGADOS POR MES",
  TOTAL_ORDER_VALUE_DELIVERED_PER_MONTH:
    "VALOR TOTAL (€) DE LOS PEDIDOS ENTREGADOS POR MES",
  TOTAL_ORDER_VALUE_DELIVERED_PER_YEAR:
    "VALOR TOTAL (€) DE LOS PEDIDOS ENTREGADOS POR AÑO",
  TOTAL_ORDER_VALUE_DELIVERED_PER_DAY:
    "VALOR TOTAL (€) DE LOS PEDIDOS ENTREGADOS POR DÍA",
  TOTAL_VALUE: "VALOR GLOBAL TOTAL",
  TOTAL_ITEM_PROMO_ACT_USED: "ARTÍCULO PROMOCIONAL ACTUAL TOTAL UTILIZADO",
  TOTAL_ITEM_COUPON_USED: "TOTAL CUPÓN DE ARTÍCULO UTILIZADO",
  TOTAL_ORDER_PER_ITEM_NO: "ARTÍCULOS TOTALES POR PEDIDO",
  TOTAL_ORDER_PER_SLOT_home_delivery:
    "PEDIDOS TOTALES POR SLOT (entrega a domicilio)",
  TOTAL_ORDER_PER_SLOT_store_delivery:
    "PEDIDOS TOTALES POR ESPACIO (entrega en tienda)",

  MALE: "MASCULINO",
  FEMALE: "MUJER",
  Select_Year: "Seleccionar año",
  Select_Month: "Seleccione el mes",
  Select_province_list: "Seleccione la lista de provincias",
  Select_sales_point: "Seleccione el puntos de venta",
  DATA: "DATOS",

  //Copywright
  Copyright_eCommerceVolutionit:
    "Copyright eCommerceVolutionit by Big Data Innovation Group 2020/2024. Reservados todos los derechos",

  //banners
  slider: "Slider",
  banners: "Pancartas",
  have_to_upload_slider: "necesitas subir una imagen deslizante",

  //category
  category: "Category",
  select_category: "Select Category",
  serial_no: "No.",
  upload: "Upload/Change",
  no_category: "There is no category",

  Shipping_cost: "Shipping cost",
  for_minimum_order_amount: "for minimum order amount",
  Shipping_Cost_for_province: "Shipping Cost for province",
  Excel_File_Header_Mismatched: "Excel File Header Mismatched",
  Add_new_shipping_cost: "Add New Shipping Cost",
  Update_shipping_Cost: "Update Shipping Cost",
  Amount_nearest_Euro: "Amount (to the nearest Euro)",
  shop_code: "Shop Code",
  shipping_cost: "Shipping Cost",

  product_points: "Product Points",
  product_points_list: "Product Points List",
  Product_Points_Update: "Product Points Update",

  Ticket_Category_Create: "Create Ticket Category",

  Points_By_Referring: "Points By Referring",
  Self_Installed_Points: "Self Installed Points",
  Total_Points: "Total Points",
  Total_Installed_By_Refer_Code: "Total Installed By Refer Code",

  earn_from_survey: "Earn from survey",
  earn_from_product: "Earn from product",
  earn_from_referral_invitation: "Earn from referral invitation",
  earn_from_using_referral_code: "Earn from using referral code",
  earn_from_target_shopping: "Earn from target shopping",

  //MLM
  mlm: "MLM",
  refer_conversion_point: "Refer Conversion Point",
  first_refer: "1er Referente",
  order_per_amount: "Monto por Pedido",
  second_refer: "2° Referente",
  third_refer: "3° Referente",

  firstReferRequired: "El primer referente debe tener valores.",
  both_fields_must_be_filled: "Ambos campos deben estar completos.",
  fillPrevious: "Debes llenar el referente anterior antes de este.",

};
