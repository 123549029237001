import React, { Component } from 'react';
import './Sidebar.css'
import Collapse from 'react-bootstrap/Collapse'
import { withRouter, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import LN from '../../common/Language'
import { side_bar_data } from './sidebar_data';
import { user_role } from '../../common/Roles';
import { currentPath, matchRoute } from '../../common/Helpers';

const checkNested = (parent) => parent.child?.map((item) => item?.childShouldActive?.find((item2) => matchRoute(item2, currentPath()) == currentPath())).find((i) => i);
const childShouldActive = (item) => item?.find((ite) => matchRoute(ite, currentPath()) == currentPath());

const Sidebar = ({_lang, sidebar_show_hide, history}) => {

    const [state, setState] = React.useState({lg: _lang})

    React.useEffect(() => {

        // Checking others extended parent item, then hide them.
        handleCollapse();
        
        // Filter allowed list
        const sidebarList = getAllowedRoutes(side_bar_data(state.lg));
        
        // Extending parent from allowed list, if child route is match wth URL path.
        sidebarList.map((item, index) => {

            // If parent already extended, then stay extend.
            if (state[index]) {
                console.log('call')
                item.child.map((child) => {
                    if(child.route === currentPath()) {
                        setState((prvState) => ({...prvState, [index]: true }))
                    } else if (checkNested(item)) {
                        setState((prvState) => ({...prvState, [index]: true }))
                    }
                })

            }
            
            // If parent not extended, then extend.
            else  {
                item.child.map((child) => {
                    if(child.route === currentPath()) {
                        setState((prvState) => ({...prvState, [index]: !state[index] }))
                    } else if (checkNested(item)) {
                        setState((prvState) => ({...prvState, [index]: !state[index] }))
                    }
                })
            }

        });

    }, [currentPath()])

    const handleCollapse = () => {
        const currentState = state;
        var keys = Object.keys(currentState);
        var filtered = keys.filter(function(key) {
            if (currentState[key] === true) return key
        });

        if(filtered.length) {
            setState((prvState) => ({...prvState, [filtered[0]]: false }))
        }
    }

    const handleSidebar = () => {
        if (window.innerWidth < 650) {
           sidebar_show_hide()
        }
    }


    const getAllowedRoutes = (route) => {
        
        const isArrayWithLength = (arr) => (Array.isArray(arr) && arr.length);

        return route.filter((item) => {

            if (!item.permission) return true;
            else if (!isArrayWithLength(item.permission)) return true;
            else return item.permission.find((item) => item  === user_role());


        });
    };
      

        let Lang =_lang;

        const sidebarList = getAllowedRoutes(side_bar_data(Lang));

        if (currentPath() != '/' && currentPath() != '/forgot_password' && currentPath() != '/forgot_password/change_password') {
            return (
                <div className="bg-dark border-right body sidebar" id="sidebar-wrapper">

                    <div style={{ padding: 10 }}>

                        <img
                            src={logo} alt="bigdata_logo"
                            className='sidebar_logo'
                            onClick={() => {
                               history.push('/home');
                                handleCollapse();
                            }} 
                        />
                        
                        {sidebarList.map((item, index) => {

                            const sidebarChildList = getAllowedRoutes(item.child);

                            if(item.child.length === 0) {
                                return (
                                    <div key={index} className="sidebar_item_name_without_child p-0" >
                                        <Link
                                            to={item.route}
                                            id={item.route}
                                            style={{padding: 10}}
                                            onClick={() => {
                                                handleCollapse();
                                                handleSidebar();
                                            }}
                                            className={`d-flex sidebar_item ${currentPath() === item.route || childShouldActive(item.childShouldActive) ? 'sidebar_router_link_active' : 'sidebar_router_link_inactive text-white'}`}
                                        >
                                                {item.label}
                                        </Link>
                                    </div>
                                )
                            } else {
                                return (
                                <div key={index} className="sidebar_item_name_with_child p-0">
                                    
                                    <div style={{padding: 10}}
                                        onClick={() => {
                                            setState((prvState) => ({...prvState, [index]: !state[index] }));
                                            handleCollapse();
                                        }} >
                                        <div className='d-flex align-items-center justify-content-between' >
                                            {item.label}
                                            {
                                                state[index] ?
                                                    <img src={require('../../assets/images/arrow-up.png')} alt="bigdata_logo" className="sidebar_dropdown_icon" />
                                                    :
                                                    <img src={require('../../assets/images/arrow-down.png')} alt="bigdata_logo" className="sidebar_dropdown_icon" />
                                            }
                                        </div>
                                    </div>

                                    <Collapse in={state[index]} >
                                        <div className='mt-2'>
                                            {sidebarChildList.map((child_item, i) => (
                                                <div key={i} className="sidebar_child_item p-0">
                                                    <Link
                                                        to={child_item.route}
                                                        id={child_item.route}
                                                        onClick={() => {
                                                            handleSidebar();
                                                        }}
                                                        className={`d-flex sidebar_item p-2 ${currentPath() === child_item.route || childShouldActive(child_item.childShouldActive) ? 'sidebar_router_link_active' : 'sidebar_router_link_inactive'}`}
                                                    >
                                                            {child_item.label}
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>)
                            }

                        })}

                    </div>
                </div>
            );
        } else {
            return (
                <div />
            )
        }
}


export default withRouter(Sidebar);
