import { lazy } from 'react';
import Roles from '../../common/Roles';


const ChangePassword = lazy(() => import('../../pages/my_account/ChangePassword'));
const SecurityQuestions = lazy(() => import('../../pages/my_account/SecurityQuestions'));

export const AccountRoutes = [
  {
    path: '/my_account/change_password',
    exact: true,
    component: ChangePassword,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/my_account/security_questions',
    exact: true,
    component: SecurityQuestions,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
