import moment from 'moment';
import Constants from "./Constants";

export const statusColor = (statusId) => {
    if (statusId === Constants.POINT_STATUS.approved) return 'success'
    if (statusId === Constants.POINT_STATUS.rejected) return 'danger'
    if (statusId === Constants.POINT_STATUS.earned) return 'primary'
    if (statusId === 4) return 'info'
    if (statusId === 5) return 'warning'
    if (statusId === 6) return 'dark'
    if (statusId === 7) return 'light'
    if (statusId === 8) return 'warning'
    return 'secondary'
}

export const statusName = (statusId) => {
  if (statusId === Constants.POINT_STATUS.approved) return 'Approved'
  if (statusId === Constants.POINT_STATUS.rejected) return 'Rejected'
  return 'Pending'
}

export const dateFormat = (inputDate) => {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

  return `${date}-${month}-${year}`;
}

export const matchRoute = (route='', url='') => {
  const splitRoute = route.split(':id');
  if (splitRoute.length === 1) return route;
  return splitRoute[0] +( url.match(/\/\d+\/+/)?.[0].match(/\d+/)[0] || url.match(/\/\d+/)?.[0].match(/\d+/)[0]) + splitRoute[1];
}

export const currentPath = () => window.location.pathname;

export const showDate = (date, format = 'DD-MM-YYYY hh:mm:ss') => date ? moment(date).format(format) : '---';

export const formatViewsCount = (views) => {
  if (views < 1000) {
    return views.toString(); // For numbers less than 1K, show as is.
  } else if (views >= 1000 && views < 1_000_000) {
    return (views / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // For thousands
  } else if (views >= 1_000_000 && views < 1_000_000_000) {
    return (views / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // For millions
  } else if (views >= 1_000_000_000) {
    return (views / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'; // For billions
  }
}
// // Test cases:
// console.log(formatViewsCount(999));       // "999"
// console.log(formatViewsCount(1000));      // "1K"
// console.log(formatViewsCount(12000));     // "12K"
// console.log(formatViewsCount(1500000));   // "1.5M"
// console.log(formatViewsCount(2000000000));// "2B"



export function convertTimestamp(timestamp) {
  // Convert timestamp (milliseconds) to seconds
  const seconds = Math.floor(timestamp / 1000)

  // Convert timestamp to minutes
  const minutes = Math.floor(seconds / 60)

  // Convert timestamp to hours
  const hours = Math.floor(minutes / 60)

  // Convert timestamp to days
  const days = Math.floor(hours / 24)

  // Convert timestamp to months
  const months = Math.floor(days / 30)

  // Convert timestamp to years
  const years = Math.floor(days / 365)

  return {
    seconds: seconds,
    minutes: minutes,
    hours: hours,
    days: days,
    months: months,
    years: years,
  }
}

// // Example usage:
// const timestamp = 1620000000; // Example timestamp in milliseconds
// const result = convertTimestamp(timestamp);

// console.log(`Seconds: ${result.seconds}`); // Output: e.g., "Seconds: 1620000"
// console.log(`Minutes: ${result.minutes}`); // Output: e.g., "Minutes: 27000"
// console.log(`Hours: ${result.hours}`);     // Output: e.g., "Hours: 450"
// console.log(`Days: ${result.days}`);       // Output: e.g., "Days: 18"
// console.log(`Years: ${result.years}`);     // Output: e.g., "Years: 0"