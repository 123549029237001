const Roles = {
  admin: 'ADMIN',
  branch_user: 'BRANCH_USER'
}
export const user_role = () => {
  const user_role_id = JSON.parse(localStorage.getItem('role_id'));
  return user_role_id === 5 ? Roles.branch_user : Roles.admin;

  // switch(user_role_id) {
  //   case 5:
  //     return Roles.branch_user;
  //   default:
  //    return Roles.admin;
  // }
}
  

export default Roles