import Global from '../common/Global';

export default class LogoutService{
    static async logout()
    {
        let ACCESS_TOKEN = await localStorage.getItem('login_token');

        try{
            let response= await fetch(Global.BASE_URL+Global.LOGOUT_URI, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    contentType: false,
                    Authorization: 'Bearer ' + ACCESS_TOKEN
                },
                body: JSON.stringify({
                    // username: u_name,
                    // password: u_password,
                }),
            });
            let responseJsonData = await response.json();
            return responseJsonData;

        }

        catch (error) {
            console.error(error);
        }
      
    }
}