import { lazy } from 'react';
import Roles from '../../common/Roles';

const SurveySetList = lazy(() => import('../../pages/advance_profiling/SurveySetList'));
const SurveyCreate = lazy(() => import('../../pages/advance_profiling/SurveyCreate'));
const SurveyUpdate = lazy(() => import('../../pages/advance_profiling/SurveyUpdate'));
const SurveyAddQuestions = lazy(() => import('../../pages/advance_profiling/SurveyAddQuestions'));
const SurveyDetails = lazy(() => import('../../pages/advance_profiling/SurveyDetails'));
const ParticipantsList = lazy(() => import('../../pages/advance_profiling/ParticipantsList'));

export const SurveyRoutes = [
  {
    path: '/survey/list',
    exact: true,
    component: SurveySetList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/survey/create',
    exact: true,
    component: SurveyCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/survey/:id/edit',
    exact: true,
    component: SurveyUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/survey/:id/questions/create',
    exact: true,
    component: SurveyAddQuestions,
    meta: {
      permissions: [Roles.admin],
    },
  },
 
  {
    path: '/survey/:id/details',
    exact: true,
    component: SurveyDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },

  {
    path: '/survey/:id/participants/list',
    exact: true,
    component: ParticipantsList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
