import { lazy } from 'react';
import Roles from '../../common/Roles';

const ProductHome = lazy(() => import('../../pages/home/product_graph/ProductHome'));
const OrderHome = lazy(() => import('../../pages/home/order_graph/OrderHome'));
const CustomerHome = lazy(() => import('../../pages/home/customer_graph/CustomerHome'));


export const GraphRoutes = [
  {
    path: '/product/graph',
    exact: true,
    component: ProductHome,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    //   resource: PROCUREMENT.REQUISITION.REQUISITION_WRITE,
    },
  },
  {
    path: '/order/graph',
    exact: true,
    component: OrderHome,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/customer/graph',
    exact: true,
    component: CustomerHome,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  
]
