import { lazy } from 'react';
import Roles from '../../common/Roles';


const FidelityCardRequestList = lazy(() => import('../../pages/fidelity_card_request/FidelityCardRequestList'));
const FidelityRequestCardDetails = lazy(() => import('../../pages/fidelity_card_request/FidelityCardDetails'));
const NewFidelityList = lazy(() => import('../../pages/fidelity_card_request/NewFidelityList'));

const FidelityCardUpload = lazy(() => import('../../pages/fidelity_card/FidelityCardUpload'));
const FidelityCardList = lazy(() => import('../../pages/fidelity_card/FidelityCardList'));
const FidelityCardDetails = lazy(() => import('../../pages/fidelity_card/FidelityCardDetails')); 

export const FidelityRoutes = [
  {
    path: '/fidelity/card/requests',
    exact: true,
    component: FidelityCardRequestList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/fidelity/card/requests/:id/details',
    exact: true,
    component: FidelityRequestCardDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/fidelity/card/upload',
    exact: true,
    component: FidelityCardUpload,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/fidelity/card/list',
    exact: true,
    component: FidelityCardList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/fidelity/card/:id/details',
    exact: true,
    component: FidelityCardDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },

  {
    path: '/fidelity/card/requests/new',
    exact: true,
    component: NewFidelityList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
