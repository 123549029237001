import { lazy } from 'react';
import Roles from '../../common/Roles';




const ProductInfoCreate = lazy(() => import('../../pages/product_info/ProductInfoCreate'));
const ProductInfoList = lazy(() => import('../../pages/product_info/ProductInfoList'));
const ProductInfoDetails = lazy(() => import('../../pages/product_info/ProductInfoDetails'));
const ProductInfoUpdate = lazy(() => import('../../pages/product_info/ProductInfoUpdate'));


export const ProductsInfoRoutes = [
  {
    path: '/product/info/create',
    exact: true,
    component: ProductInfoCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/product/info/list',
    exact: true,
    component: ProductInfoList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/info/:id/details',
    exact: true,
    component: ProductInfoDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/info/:id/edit',
    exact: true,
    component: ProductInfoUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
