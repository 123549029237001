import { lazy } from 'react';
import Roles from '../../common/Roles';

const LanguageChangeRefresh = lazy(() => import('../../pages/refresh_route/LanguageChangeRefresh'));


export const RefreshRoutes = [
  {
    path: '/refresh',
    exact: true,
    component: LanguageChangeRefresh,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
]
