import { lazy } from 'react';
import Roles from '../../common/Roles';

const TicketsList = lazy(() => import('../../pages/tickets/TicketsList'));
const TicketDetails = lazy(() => import('../../pages/tickets/TicketDetails'));
const TicketsNotifyList = lazy(() => import('../../pages/tickets/TicketsNotifyList'));
const TicketCategoryList = lazy(() => import('../../pages/tickets/TicketCategoryList'));

export const TicketsRoutes = [
  {
    path: '/tickets/list',
    exact: true,
    component: TicketsList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/tickets/:id/details',
    exact: true,
    component: TicketDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/tickets/notifications',
    exact: true,
    component: TicketsNotifyList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/tickets/categories',
    exact: true,
    component: TicketCategoryList,
    meta: {
      permissions: [Roles.admin],
    },
  },

]
