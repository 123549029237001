import { EN } from "./languages/en";
import { ES } from "./languages/es";
import { IT } from "./languages/it";

export default class Languages {

    static LANGUAGE_NAME = 'IT';

    static EN = {...EN};

    static IT = {...IT};

    static ES = {...ES}
}