import { lazy } from 'react';
import Roles from '../../common/Roles';

const SalesPointsList = lazy(() => import('../../pages/sales_points/SalesPointsList'));
const DeliverySlotCreate = lazy(() => import('../../pages/delivery_slot/DeliverySlotCreate'));
const SpecialDeliveryDate = lazy(() => import('../../pages/delivery_slot/SpecialDeliveryDate'));


export const DeliveryRoutes = [
  {
    path: '/delivery_slot',
    exact: true,
    component: SalesPointsList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/create_delivery_slot',
    exact: true,
    component: DeliverySlotCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/special_delivery_dates',
    exact: true,
    component: SpecialDeliveryDate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
