import { lazy } from 'react';
import Roles from '../../common/Roles';


const Privacy = lazy(() => import('../../pages/privacy/Privacy'));
const PrivacyHistory = lazy(() => import('../../pages/privacy/PrivacyHistory'));
const UserList = lazy(() => import('../../pages/privacy/UserList'));

export const PrivacyRoutes = [
  {
    path: '/privacy',
    exact: true,
    component: Privacy,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/privacy/privacy_list',
    exact: true,
    component: PrivacyHistory,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/privacy_accept/user_list',
    exact: true,
    component: UserList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
