import { lazy } from 'react';
import Roles from '../../common/Roles';

const Categories = lazy(() => import('../../pages/categories/Categories'));

export const CategoriesRoutes = [
  {
    path: '/categories',
    exact: true,
    component: Categories,
    meta: {
      permissions: [Roles.admin],
    },
  },

]
