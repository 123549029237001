import { lazy } from 'react';
import Roles from '../../common/Roles';

const Contact = lazy(() => import('../../pages/contact/Contact'));
const ContactAdd = lazy(() => import('../../pages/contact/ContactAdd'));


export const ContactRoutes = [
  {
    path: '/contact/update/:id/:type',
    exact: true,
    component: ContactAdd,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/contact',
    exact: true,
    component: Contact,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
