import { lazy } from 'react';
import Roles from '../../common/Roles';

const Notice = lazy(() => import('../../pages/notice/Notice'));


export const NoticeRoutes = [
  {
    path: '/notice',
    exact: true,
    component: Notice,
    meta: {
      permissions: [Roles.admin],
    },
  },
  
]
