import { lazy } from 'react';
import Roles from '../../common/Roles';



const EventCreate = lazy(() => import('../../pages/events/EventCreate'));
const EventList = lazy(() => import('../../pages/events/EventList'));
const EventDetails = lazy(() => import('../../pages/events/EventDetails'));
const BookingUserList = lazy(() => import('../../pages/events/BookingUserList'));
const EventUpdate = lazy(() => import('../../pages/events/EventUpdate'));


//

export const EventRoutes = [
  {
    path: '/event/create',
    exact: true,
    component: EventCreate,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/event/list',
    exact: true,
    component: EventList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/event/:id/details',
    exact: true,
    component: EventDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/event/:id/booking',
    exact: true,
    component: BookingUserList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/event/:id/edit',
    exact: true,
    component: EventUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
