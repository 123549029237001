import React, {lazy} from 'react'
import { Route, Redirect } from 'react-router-dom'
import RouteAuthentication from './RouteAuthentication'
import { user_role } from '../common/Roles'

const NotFound = lazy(() => import('../pages/wrong_route/PageNotFound'));

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (RouteAuthentication.isAuthenticated()) {
          const has_permission = rest.meta.permissions?.find((permission) => permission === user_role())
          if (has_permission) {
            return <Component {...props} />
          } else {
            return <NotFound />
          }
        } else {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      }}
    />
  )
}
