import { lazy } from 'react';
import Roles from '../../common/Roles';


const OrderList = lazy(() => import('../../pages/product_order/OrderList'));
const OrderDetails = lazy(() => import('../../pages/product_order/OrderDetails'));
const NewChatProductList = lazy(() => import('../../pages/product_order/NewChatProductList'));
const OrderDetailsPrint = lazy(() => import('../../pages/product_order/OrderDetailsPrint'));



export const ProductOrderRoutes = [
  {
    path: '/product/order/list',
    exact: true,
    component: OrderList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/order/:id/details',
    exact: true,
    component: OrderDetails,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/order/list/notifications',
    exact: true,
    component: NewChatProductList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  {
    path: '/product/order/:id/OrderDetailsPrint',
    exact: true,
    component: OrderDetailsPrint,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  
]
