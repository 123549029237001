import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../assets/bootstrap.min.css'
import Header from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import RoutersBackup from '../../navigators/RoutersListBackup'
import Routers from '../../navigators/RoutersList'
import LN from '../../common/Language'

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_sidebar: true,
            body_margin_left: 250,
            lang: LN.LANGUAGE_NAME
        };
    }
    async componentDidMount() {
        if (window.innerWidth < 650) {
            this.setState({ show_sidebar: !this.state.show_sidebar })
            this.setState({ body_margin_left: 0 })
        }

        let current_lang = await localStorage.getItem('selected_lang');
        if (current_lang) {
            this.setState({ lang: current_lang });
            LN.LANGUAGE_NAME = current_lang;
        }


    }
    show_hide = () => {
        this.setState({ body_margin_left: this.state.body_margin_left === 0 ? 250 : 0 })
        this.setState({ show_sidebar: !this.state.show_sidebar })
    }
    on_logout_margin = () => {
        this.setState({ show_sidebar: true })
        this.setState({ body_margin_left: 250 })
    }
    language_change_notify = (lang) => {
        this.setState({ lang: LN.LANGUAGE_NAME });
    }
    render() {
        return (
            <BrowserRouter>
                <div>
                    {
                        this.state.show_sidebar ?
                            <div className="d-flex"  >
                                <Sidebar sidebar_show_hide={() => this.show_hide()} _lang={this.state.lang} />
                            </div>
                            :
                            <div />
                    }
                    <Header sidebar_show_hide={() => this.show_hide()}
                        on_logout_function={() => this.on_logout_margin()}
                        language_change_notify={() => this.language_change_notify()}
                    />
                    <div style={{ marginLeft: this.state.body_margin_left, }}>
                        {/* <RoutersBackup /> */}
                        <Routers/>
                    </div>

                </div>

            </BrowserRouter >


        );
    }
}

export default Main;