import { lazy } from 'react';
import Roles from '../../common/Roles';



const MessageSend = lazy(() => import('../../pages/communication/MessageSend'));
const MessageList = lazy(() => import('../../pages/communication/MessageList'));
const MessageUpdate = lazy(() => import('../../pages/communication/MessageUpdate'));
const MessageDetails = lazy(() => import('../../pages/communication/MessegeDetails'));


//

export const MessageRoutes = [
  {
    path: '/message/send',
    exact: true,
    component: MessageSend,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/message/list',
    exact: true,
    component: MessageList,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/message/:id/details',
    exact: true,
    component: MessageDetails,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/message/:id/edit',
    exact: true,
    component: MessageUpdate,
    meta: {
      permissions: [Roles.admin],
    },
  },
]
