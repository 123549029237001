import { lazy } from 'react';
import Roles from '../../common/Roles';

const CouponsUpload = lazy(() => import('../../pages/coupons/CouponsUpload'));
const CouponsList = lazy(() => import('../../pages/coupons/CouponsList'));


export const CouponRoutes = [
  {
    path: '/coupon/coupon_upload',
    exact: true,
    component: CouponsUpload,
    meta: {
      permissions: [Roles.admin],
    },
  },
  {
    path: '/coupon/coupon_list',
    exact: true,
    component: CouponsList,
    meta: {
      permissions: [Roles.admin, Roles.branch_user],
    },
  },
  
]
